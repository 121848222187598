import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

const rootDiv = window.document.getElementById("root");
const root = ReactDOM.createRoot(rootDiv);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// reportWebVitals(console.log);
