import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Grid,
  Link,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { Desktop, LgBreakpoint, Mobile } from "../layout";
import { GetStarted, NavButton, NavMenuButton } from "./Button";
import { NavLogo } from "./Logo";
import { styled } from "@mui/material/styles";
import logo from "../vendor/static/logo/Logo-Primary-Color-Dark.png";
import { ChevronDownIcon, CloseIcon, MenuIcon } from "./Icon";
import { useSessionStorage } from "../hooks/storage";
import { useLocation } from "react-router-dom";

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginTop: 0,
    marginBottom: 0,
  },
});
const MainAccordionSummary = styled((props) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ height: "72px" }}
  >
    <Link href="/">
      <Box
        component="img"
        src={logo}
        sx={{
          height: "24px",
          width: "102px",
          pl: 2,
        }}
      />
    </Link>
    <MuiAccordionSummary {...props} />
  </Stack>
))({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
});

/**
 * @component
 * @module Navigation
 * @type {React | JSX}
 * @category Client
 * @subcategory Components
 * @description
 * Main navigation component
 * @example <caption>Usage:</caption>
 * return(
 *   <Navigation />
 * )
 * @return {JSX.Element}
 */
const Navigation = () => {
  const location = useLocation();
  const route = location.pathname.toString().replace(/^\/|\/$/g, "");
  const [showAnimation, setShowAnimation] = useSessionStorage(
    "showTransitions",
    true
  );
  const handleExit = () => {
    setShowAnimation(false);
  };
  const [menuExpanded, setMenuExpanded] = useState(false);
  const handleMenuChange = (menu) => (event, isExpanded) => {
    setMenuExpanded(isExpanded ? menu : false);
  };

  return (
    <Grid container item sx={{ backgroundColor: "ink.300" }}>
      <Grid container item justifyContent="center" maxWidth={1440} mx="auto">
        <Grid item xs={12} zeroMinWidth>
          <Mobile>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              elevation={0}
              square
              disableGutters
              onChange={handleMenuChange("main-menu")}
              expanded={menuExpanded === "main-menu"}
              sx={{
                backgroundColor:
                  menuExpanded === "main-menu" ? "ink.400" : "ink.300",
              }}
            >
              <MainAccordionSummary
                onClick={() => {}}
                expandIcon={
                  menuExpanded === "main-menu" ? (
                    <CloseIcon
                      sx={{
                        color: "neutral.white",
                        width: "36px",
                        height: "36px",
                      }}
                    />
                  ) : (
                    <MenuIcon
                      sx={{
                        color: "neutral.white",
                        width: "36px",
                        height: "36px",
                      }}
                    />
                  )
                }
              />
              <AccordionDetails>
                <Box minHeight="100vh" width="100%" textAlign="left">
                  <Stack direction="column" rowGap={2}>
                    <Accordion
                      TransitionProps={{ unmountOnExit: true }}
                      elevation={0}
                      square
                      disableGutters
                      sx={{
                        backgroundColor:
                          menuExpanded === "main-menu" ? "ink.400" : "ink.300",
                        "& .MuiButtonBase-root": {
                          minHeight: "25px !important",
                          maxHeight: "25px !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ChevronDownIcon sx={{ color: "neutral.white" }} />
                        }
                      >
                        <Typography variant="body2" color="neutral.white">
                          Solutions
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1}>
                          <Link
                            href="/logistics"
                            underline="none"
                            variant="body2"
                            color="black.light"
                          >
                            Logistics
                          </Link>
                          <Link
                            href="e-commerce"
                            underline="none"
                            variant="body2"
                            color="black.light"
                          >
                            E-commerce
                          </Link>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Link
                      href="/how-it-works"
                      underline="none"
                      variant="body2"
                      color="neutral.white"
                      pl={2}
                    >
                      How it Works
                    </Link>
                    <Accordion
                      TransitionProps={{ unmountOnExit: true }}
                      elevation={0}
                      square
                      disableGutters
                      sx={{
                        backgroundColor:
                          menuExpanded === "main-menu" ? "ink.400" : "ink.300",
                        "& .MuiButtonBase-root": {
                          minHeight: "25px !important",
                          maxHeight: "25px !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ChevronDownIcon sx={{ color: "neutral.white" }} />
                        }
                      >
                        <Typography variant="body2" color="neutral.white">
                          Resources
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1}>
                          <Link
                            href="https://support.boxc.com/support/home"
                            underline="none"
                            variant="body2"
                            color="black.light"
                          >
                            Support
                          </Link>
                          <Link
                            href="https://api.boxc.com/"
                            underline="none"
                            variant="body2"
                            color="black.light"
                          >
                            API
                          </Link>
                          <Link
                            href="/blog"
                            underline="none"
                            variant="body2"
                            color="black.light"
                          >
                            Blog
                          </Link>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Link
                      href="/about"
                      underline="none"
                      variant="body2"
                      color="neutral.white"
                      pl={2}
                    >
                      About
                    </Link>
                    <Link
                      href="/track"
                      underline="none"
                      variant="body2"
                      color="neutral.white"
                      pl={2}
                    >
                      Track
                    </Link>
                    <Link
                      href="https://accounts.boxc.com/login"
                      underline="none"
                      variant="body2"
                      color="neutral.white"
                      pl={2}
                    >
                      Login
                    </Link>
                    <GetStarted width="auto" height="46px" />
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Mobile>
          <Desktop>
            {route === "" ? (
              <Slide
                direction="down"
                appear={route === "" && LgBreakpoint && showAnimation}
                timeout={600}
                mountOnEnter
                unmountOnExit
                in
                addEndListener={handleExit}
              >
                <Stack direction="row" sx={{ backgroundColor: "ink.300" }}>
                  <Box bgcolor="black.main" flexShrink={1}>
                    <Link href="/" underline="none">
                      <NavLogo />
                    </Link>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    flexGrow={1}
                  >
                    <Stack direction="row" alignItems="center">
                      <NavMenuButton
                        text="Solutions"
                        menuItems={[
                          { name: "Logistics" },
                          { name: "E-commerce" },
                        ]}
                      />
                      <NavButton text="How It Works" />
                      <NavMenuButton
                        text="Resources"
                        menuItems={[
                          {
                            name: "Support",
                            link: "https://support.boxc.com/support/home",
                          },
                          { name: "API", link: "https://api.boxc.com/" },
                          { name: "Blog" },
                        ]}
                      />
                      <NavButton text="About" />
                    </Stack>
                    <Stack direction="row" alignItems="center" pr={6}>
                      <NavButton text="Track" />
                      <NavButton
                        text="Login"
                        link="https://accounts.boxc.com/login"
                      />
                      <GetStarted width="auto" />
                    </Stack>
                  </Stack>
                </Stack>
              </Slide>
            ) : (
              <Box>
                <Stack direction="row" sx={{ backgroundColor: "ink.300" }}>
                  <Box bgcolor="black.main" flexShrink={1}>
                    <Link href="/" underline="none">
                      <NavLogo />
                    </Link>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    flexGrow={1}
                  >
                    <Stack direction="row" alignItems="center">
                      <NavMenuButton
                        text="Solutions"
                        menuItems={[
                          { name: "Logistics" },
                          { name: "E-commerce" },
                        ]}
                      />
                      <NavButton text="How It Works" />
                      <NavMenuButton
                        text="Resources"
                        menuItems={[
                          {
                            name: "Support",
                            link: "https://support.boxc.com/support/home",
                          },
                          { name: "API", link: "https://api.boxc.com/" },
                          { name: "Blog" },
                        ]}
                      />
                      <NavButton text="About" />
                    </Stack>
                    <Stack direction="row" alignItems="center" pr={6}>
                      <NavButton text="Track" />
                      <NavButton
                        text="Login"
                        link="https://accounts.boxc.com/login"
                      />
                      <GetStarted width="auto" />
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
          </Desktop>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navigation;
