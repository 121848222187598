import { forwardRef, useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import { Grid, Stack, Typography } from "@mui/material";
import _ from "underscore";
import { getDynamicIcon } from "./Icon";

/**
 *
 * @component
 * @module ColumnCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props {page, index, columns}
 * @param {string} props.page the page containing this column card (example: "home")
 * @param {number} props.index the index of the column card on your page (0 indexed)
 * @param {Object} props.columnConfig column configuration object
 * @description
 * Strapi column-card component
 * @example <caption>Usage:</caption>
 * return(
 *   <ColumnCard
 *      page="example-page"
 *      index={0}
 *      columnConfig={{
 *        rowGap:2
 *      }}
 *   />
 * )
 */
const ColumnCard = forwardRef((props, ref) => {
  const { page, index, columnConfig } = props;
  const restOf = _(props).omit(["page", "index", "columnConfig"]);
  const { columnCards, fetchColumnCards, fetchingColumnCards } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (columnCards == null && !fetchingColumnCards.current) {
      fetchColumnCards();
    } else {
      if (!!columnCards) {
        const card = columnCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : false);
      }
    }
  }, [page, index, columnCards, fetchColumnCards, fetchingColumnCards, data]);

  return (
    <Grid container item {...restOf} ref={ref}>
      {data != null &&
        data !== false &&
        data["columns"].map((col) => {
          const { id, icon, title, content } = col;
          const titleColor =
            title["color"].toLowerCase() === "dark"
              ? "black.main"
              : "gray.main";
          const contentColor =
            content["color"].toLowerCase() === "dark" ? "ink.200" : "gray.main";

          return (
            <Grid
              key={`column-card-${data["id"]}-column-${id}`}
              container
              item
              xs={12}
              lg={12 / data["columns"].length}
            >
              <Stack direction="column" {...columnConfig}>
                {!!icon && getDynamicIcon({ name: icon })}
                <Typography variant={title["font"]} color={titleColor}>
                  {title["value"]}
                </Typography>
                <Typography variant={content["font"]} color={contentColor}>
                  {content["value"]}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
    </Grid>
  );
});

export default ColumnCard;
