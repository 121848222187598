import { Box, Grid, Icon, Stack, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";

const ErrorHero = () => {
  return (
    <Grid item xs={12} bgcolor="error.main" pl={{ xs: 2, lg: 25 }} py={5}>
      <Box maxWidth={1440} mx="auto">
        <Stack direction="row" spacing={2} alignItems="center">
          <Icon
            component={Warning}
            fontSize="large"
            sx={{ color: "background.default" }}
          />
          <Typography variant="xlTitle" color="white">
            Error
          </Typography>
        </Stack>
      </Box>
    </Grid>
  );
};

export default ErrorHero;
