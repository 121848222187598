const palette = {
  primary: {
    main: "#2E8049",
    dark: "#12331D",
    light: "#B8E5C7",
  },
  background: {
    default: "#EDECEA",
  },
  black: {
    main: "#16181F",
    dark: "#36373B",
    light: "#ABADB2",
  },
  green: {
    main: "#2E8049",
    dark: "#12331D",
    light: "#B8E5C7",
    hover: "#25663A",
  },
  gray: {
    main: "#EDECEA",
    400: "#A1A1AA",
  },
  ink: {
    50: "#ABADB2",
    100: "#7A7C80",
    200: "#36373B",
    300: "#21232A",
    400: "#16181F",
  },
  lavender: {
    main: "#BF83C0",
    dark: "#321233",
    light: "#E5B8E5",
  },
  neutral: {
    white: "#FFFFFF",
    100: "#FAFAFA",
    200: "#F5F5F5",
    300: "#EDECEA",
  },
  yellow: {
    main: "#E5A830",
    dark: "#3B2D11",
    light: "#EADBC0",
  },
  navButton: {
    main: "#21232A",
    dark: "#ABADB2",
    light: "#EDECEA",
  },
  error: {
    main: "#d32f2f",
    dark: "#c62828",
    light: "#ef5350",
    contrastText: "#fff",
  },
};

export default palette;
