import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Desktop, Mobile } from "../layout";
import useStrapi from "../hooks/useStrapi";

const StickerCard = (props) => {
  const { page, index } = props;
  const { stickerCards, fetchStickerCards, fetchingStickerCards } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (stickerCards == null && !fetchingStickerCards.current) {
      fetchStickerCards();
    } else {
      if (!!stickerCards) {
        const card = stickerCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : null);
      }
    }
  }, [stickerCards, fetchStickerCards, fetchingStickerCards, page, index]);

  return (
    <Grid container item>
      <Grid container item maxWidth={1440} mx="auto">
        {data == null ? (
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mt: { xs: 5, lg: 25 },
                mb: { xs: 2, lg: 10 },
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box
              textAlign="center"
              sx={{ mt: { xs: 5, lg: 25 }, mb: { xs: 2, lg: 10 } }}
            >
              <Typography
                variant={
                  data["title"]["font"] ? data["title"]["font"] : "xlTitle"
                }
                color={
                  data["title"]["color"]
                    ? data["title"]["color"].toLowerCase() === "dark"
                      ? "black.main"
                      : "gray.main"
                    : "black.main"
                }
              >
                {data["title"]["value"]}
              </Typography>
            </Box>
            <Desktop>
              <Box ml={25} mt={10}>
                {data["stickers"].map((sticker, index) => {
                  const { color, title, rotation, leftPosition, topPosition } =
                    sticker;

                  return (
                    <Box
                      key={`sticker-card-sticker-desktop-${index}`}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0px 40px",
                        position: "relative",
                        width: "fit-content",
                        height: "57px",
                        left: `${leftPosition}px`,
                        top: `${topPosition}px`,
                        backgroundColor: `${color["name"]}.${color["variant"]}`,
                        transform: `rotate(${rotation}deg)`,
                      }}
                    >
                      <Typography
                        variant={title["font"] ? title["font"] : "h4"}
                        color={
                          title["color"]
                            ? `${title["color"]["name"]}.${title["color"]["variant"]}`
                            : "black.main"
                        }
                      >
                        {title["value"]}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Desktop>
            <Mobile>
              <Box mb={{ xs: 5, lg: 20 }}>
                <Stack
                  direction="column"
                  rowGap={{ xs: 10, sm: 15 }}
                  sx={{
                    display: {
                      lg: "none",
                    },
                    alignItems: "center",
                    alignSelf: "stretch",
                    py: 5,
                  }}
                >
                  {data["stickers"].map((sticker, index) => {
                    const { color, title, rotation } = sticker;

                    return (
                      <Box
                        key={`sticker-card-sticker-mobile-${index}`}
                        mx="auto"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            px: { xs: 2, sm: 3 },
                            py: { xs: 1, sm: 2 },
                            backgroundColor: `${color["name"]}.${color["variant"]}`,
                            transform: `rotate(${rotation}deg)`,
                            mx: "auto",
                          }}
                        >
                          <Typography
                            variant={title["font"] ? title["font"] : "h4"}
                            color={
                              title["color"]
                                ? `${title["color"]["name"]}.${title["color"]["variant"]}`
                                : "black.main"
                            }
                          >
                            {title["value"]}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Mobile>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default StickerCard;
