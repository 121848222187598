import Timeline from "@mui/lab/Timeline";
import _ from "underscore";
import TimelineDate from "./TimelineDate";
import randomkey from "randomkey";

/**
 *
 * @component
 * @module TimelineTracker
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Array<Object>} props.trackerEvents array of tracking event objects.
 * @description
 * Component for showing timeline of tracking events for a user's searched package.
 * @example <caption>usage:</caption>
 * const trackerEvents = Array<Object>;
 * return ( <TimelineTracker trackerEvents={trackerEvents} /> );
 */
const TimelineTracker = (props) => {
  const { trackerEvents } = props;
  const groupEvents = _(trackerEvents).groupBy((trackerEvent) => {
    return trackerEvent.time.split(" ")[0];
  });
  const groups = _(Object.values(groupEvents));

  return (
    <Timeline position="right">
      {groups.map((group) => {
        const groupDate = group[0].time.split(" ")[0];
        const randomKey = randomkey(10, randomkey.safe);

        return (
          <TimelineDate
            key={randomKey}
            trackerDate={groupDate}
            trackerEvents={group}
          />
        );
      })}
    </Timeline>
  );
};

export default TimelineTracker;
