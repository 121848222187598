import { Grid } from "@mui/material";
import Bottom from "../../components/Bottom";
import LogisticsHero from "./Hero";
import Navigation from "../../components/Navigation";
import { LogisticsHelmet } from "../../components/MetaHelmet";
import ListCard from "../../components/ListCard";
import CallToAction from "../../components/CallToAction";
import StickerCard from "../../components/StickerCard";
import SummaryCard from "../../components/SummaryCard";

/**
 * @component
 * @module Logistics
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/logistics" element={<Logistics />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Logistics Page
 */
const Logistics = () => {
  return (
    <Grid container item alignItems="center">
      <LogisticsHelmet />
      <Navigation />
      <LogisticsHero />
      {/* HOW BOXC WORKS FOR */}
      <StickerCard page="logistics" index={0} />
      {/* WHAT YOU GET */}
      <ListCard page="logistics" index={0} />
      {/* BENEFITS */}
      <ListCard page="logistics" index={1} />
      {/* CALL TO ACTION */}
      <CallToAction page="logistics" index={0} />
      {/* ADAPTABLE AND SCALABLE PLATFORM */}
      <SummaryCard page="logistics" index={0} />
      <Bottom showPreFooter showNav />
    </Grid>
  );
};

export default Logistics;
