import { Box, Grid, Typography } from "@mui/material";
import Navigation from "../../components/Navigation";
import { BlogHelmet } from "../../components/MetaHelmet";
import Bottom from "../../components/Bottom";
import { useEffect, useState } from "react";
import axios from "axios";
import Error from "../error";
import Loader from "./Loader";
import { getDateMonthString } from "../../utils/dates";
import BlogThumbnail from "./thumbnail";
import _ from "underscore";

/**
 * @component
 * @module Blog
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/blog" element={<Blog />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Blog Landing Page
 */
const Blog = () => {
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchPosts = () => {
    axios
      .get("/api/blog/posts")
      .then(({ data }) => {
        setIsLoading(false);
        const { success } = data;

        if (success) {
          const { posts } = data;
          setPosts(posts);
        } else {
          setPosts(null);
          setError(data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setPosts(null);
        setError(error.message);
      });
  };

  useEffect(() => {
    if (!posts) {
      setIsLoading(true);
      fetchPosts();
    }
  }, [posts]);

  return (
    <Grid container item xs={12}>
      <Navigation />
      {error ? (
        <Error key="blog-error" message={error} />
      ) : (
        <>
          <BlogHelmet />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Grid
                item
                xs={12}
                bgcolor="black.main"
                pl={{ xs: 2, lg: 25 }}
                py={5}
              >
                <Box maxWidth={1440} mx="auto">
                  <Typography variant="xlTitle" color="white">
                    Blog
                  </Typography>
                </Box>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                {!error &&
                  posts !== null &&
                  _(posts).map(({ id, attributes }) => {
                    try {
                      const {
                        content,
                        publishedAt,
                        updatedAt,
                        title,
                        author,
                        photo,
                      } = attributes;
                      const { firstname, lastname } = author
                        ? author.data.attributes
                        : { firstname: "", lastname: "" };
                      const theAuthor =
                        firstname === "" ? null : firstname + " " + lastname;
                      // const devPhotoSource = photo["data"]?"http://localhost:1337" + photo.data.attributes.formats.large.url:null;
                      const photoSource =
                        photo["data"] !== null
                          ? "https://cms.boxc.com/" +
                            photo.data.attributes.formats.large.url
                          : null;

                      //create date & update date
                      const publishedDate = new Date(publishedAt);
                      const updatedDate = new Date(updatedAt);
                      const publishedFormatted =
                        getDateMonthString(publishedDate) +
                        " " +
                        publishedDate.getDate() +
                        ", " +
                        publishedDate.getFullYear();
                      const updatedFormatted =
                        getDateMonthString(updatedDate) +
                        " " +
                        updatedDate.getDate() +
                        ", " +
                        updatedDate.getFullYear();

                      //post
                      const maxLength = 200;
                      const post =
                        content.length <= maxLength
                          ? content
                          : content.substring(
                              0,
                              Math.min(maxLength, content.lastIndexOf(" "))
                            ) + "...";
                      return (
                        <Grid item xs={12} sm={10} md={8} lg={7} key={id}>
                          <BlogThumbnail
                            id={id}
                            title={title}
                            author={theAuthor}
                            createdAt={publishedFormatted}
                            updatedAt={updatedFormatted}
                            post={post}
                            story={content}
                            // image={devPhotoSource}
                            image={photoSource}
                          />
                        </Grid>
                      );
                    } catch (e) {
                      setError(e.message);
                      return <Error key="alt-blog-error" message={error} />;
                    }
                  })}
              </Grid>
            </>
          )}
        </>
      )}
      <Bottom showPreFooter showNav />
    </Grid>
  );
};

export default Blog;
