import { Box, Grid, Slide, Stack } from "@mui/material";
import { GetStarted } from "./Button";
import { useSessionStorage } from "../hooks/storage";
import { LgBreakpoint } from "../layout";

/**
 *
 * @component
 * @module Hero
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} title text to be displayed as the Hero title
 * @param {String} subTitle text to be displayed as the Hero sub-title
 * @param {Image} image hero image
 * @param {Boolean} doTransitions variable used during page load to pivot whether or not to show animations
 * @description
 * Hero Component
 * @example <caption>Usage:</caption>
 * return (
 *   <Hero
 *     title={
 *         <Box>
 *             <Typography variant="xlTitle"> *your title* </Typography>
 *         </Box>
 *     }
 *     subTitle={
 *         <Stack>
 *             <Box>
 *                 <Typography variant="h4">
 *                      *your sub-title*
 *                 </Typography>
 *             </Box>
 *             <Box>
 *                 <Typography variant="h4">
 *                      *your sub-title*
 *                 </Typography>
 *             </Box>
 *         </Stack>
 *     }
 *     image={
 *         <Box>
 *             <img src="your/image/src.webp" alt="your image source" />
 *         </Box>
 *     }
 *     doTransitions={false}
 *   />
 * )
 * @returns {JSX.Element}
 */
const Hero = ({ title, subTitle, image, doTransitions }) => {
  const [showAnimation, setShowAnimation] = useSessionStorage(
    "showTransitions",
    true
  );
  const handleExit = () => {
    setShowAnimation(false);
  };
  const isDesktop = !!LgBreakpoint;

  return (
    <Grid container item sx={{ backgroundColor: "black.main" }}>
      <Grid
        container
        item
        sx={{
          maxWidth: "1440px",
          height: { xs: "auto", lg: "700px" },
          mx: "auto",
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          alignSelf={doTransitions ? "flex-start" : "stretch"}
        >
          {doTransitions ? (
            <Slide
              direction="up"
              appear={Boolean(!!isDesktop && showAnimation)}
              timeout={600}
              mountOnEnter
              unmountOnExit
              in
              addEndListener={handleExit}
            >
              <Stack rowGap={2}>
                {title}
                {subTitle}
                <Box ml={{ xs: 2, lg: 10 }}>
                  <GetStarted width="auto" />
                </Box>
              </Stack>
            </Slide>
          ) : (
            <Stack rowGap={2}>
              {title}
              {subTitle}
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} lg={6} alignSelf="center">
          {doTransitions ? (
            <Slide
              direction="left"
              appear={Boolean(!!isDesktop && showAnimation)}
              timeout={600}
              mountOnEnter
              unmountOnExit
              in
              addEndListener={handleExit}
            >
              <Box>{image}</Box>
            </Slide>
          ) : (
            <Box pt={{ xs: 5, lg: 0 }}>{image}</Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Hero;
