import { Helmet, HelmetProps } from "react-helmet-async";

const MetaHelmet = (props: HelmetProps) => {
  return (
    <Helmet defaultTitle="BoxC" titleTemplate="%s" prioritzeSeo>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
    </Helmet>
  );
};

export const HomeHelmet = () => (
  <MetaHelmet
    title="BoxC: International E-commerce Management Platform"
    description="BoxC is an e-commerce logistics management platform that goes beyond passively reselling labels and courier services. We enable you to control all aspects of international e-commerce logistics."
  />
);
export const RequestMoreInfoHelmet = () => (
  <MetaHelmet
    title="BoxC: Request More Information"
    description="Request more information."
  />
);
export const HowItWorksHelmet = () => (
  <MetaHelmet
    title="BoxC: How to Customize International E-commerce Shipping"
    description="More than just shipping, BoxC’s ecommerce logistics management technology simplifies every last detail of e-commerce logistics."
  />
);
export const EcommerceHelmet = () => (
  <MetaHelmet
    title="BoxC: Capabilities and Benefits for E-commerce"
    description="Take control of your international e-commerce growth."
  />
);
export const LogisticsHelmet = () => (
  <MetaHelmet
    title="BoxC: Capabilities and Benefits for Logistics"
    description="Launch or expand international shipping services without upfront costs."
  />
);
export const AboutHelmet = () => (
  <MetaHelmet
    title="BoxC: About"
    description="BoxC eliminates the limitations of traditional shipping and logistics solutions to make it easy for logistics and e-commerce businesses to grow and compete on a global scale."
  />
);
export const TermsAndConditionsHelmet = () => (
  <MetaHelmet
    title="BoxC: Terms & Conditions"
    description="Terms & Conditions"
  />
);
export const PivacyHelmet = () => (
  <MetaHelmet title="BoxC: Privacy" description="Privacy Policy" />
);
export const ResponseHelmet = () => (
  <MetaHelmet title="Thank You!" description="Thank you for contacting us." />
);
export const TrackHelmet = () => (
  <MetaHelmet title="BoxC: Track" description="Track your BoxC shipment." />
);
export const BlogHelmet = () => (
  <MetaHelmet
    title="BoxC: Blog"
    description="BoxC is an e-commerce logistics management platform that goes beyond passively reselling labels and courier services. We enable you to control all aspects of international e-commerce logistics."
  />
);
export const BlogPostHelmet = ({ title }) => (
  <MetaHelmet
    title={`BoxC: ${title}`}
    description={`BoxC blog post - ${title}`}
  />
);
export const ErrorHelmet = () => (
  <MetaHelmet title="BoxC: Error" description="Error: Oops!" />
);
