import { useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { getDynamicIcon } from "./Icon";
import { RoundButton } from "./Button";
import { Img } from "./Image";

const SummaryCard = (props) => {
  const { page, index } = props;
  const { summaryCards, fetchSummaryCards, fetchingSummaryCards } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (summaryCards == null && !fetchingSummaryCards.current) {
      fetchSummaryCards();
    } else {
      if (!!summaryCards) {
        const card = summaryCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : null);
      }
    }
  }, [summaryCards, fetchingSummaryCards, fetchSummaryCards, page, index]);

  return (
    <Grid container item flexGrow={1}>
      <Grid container maxWidth={1440} mx="auto">
        {data == null ? (
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          </Grid>
        ) : (
          <Grid container alignItems="center">
            <Grid item xs={12} lg={6}>
              <Stack
                direction="column"
                rowGap={{ xs: 2, lg: 5 }}
                px={{ xs: 2, lg: 10 }}
              >
                <Typography
                  variant={data["title"]["font"] ? data["title"]["font"] : "h1"}
                  color={
                    data["title"]["color"]
                      ? data["title"]["color"].toLowerCase() === "dark"
                        ? "black.main"
                        : "gray.main"
                      : "black.main"
                  }
                >
                  {data["title"]["value"]}
                </Typography>
                <Stack direction="column" rowGap={2}>
                  {data["list"].map((item, index) => {
                    const { icon, text } = item;

                    return (
                      <Stack
                        direction="row"
                        columnGap={2}
                        alignItems="center"
                        justifyContent="flex-start"
                        key={`summary-card-list-item-${index}`}
                      >
                        {getDynamicIcon(icon)}
                        <Typography
                          variant={text["font"] ? text["font"] : "h3"}
                          color={
                            text["color"]
                              ? text["color"].toLowerCase() === "dark"
                                ? "black.main"
                                : "gray.main"
                              : "black.main"
                          }
                        >
                          {text["value"]}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
                <Box>
                  <RoundButton
                    width="auto"
                    text={data["button"]["text"]["value"]}
                    link={data["button"]["href"]}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Img
                src={data["image"]}
                alt={`${data["title"]["value"]} Image`}
                height="auto"
                maxWidth={600}
                mx="auto"
                px={2}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SummaryCard;
