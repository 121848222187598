import { useState } from "react";
import {
  Button,
  ClickAwayListener,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Desktop, Mobile } from "../layout";
import { ChevronDownIcon } from "./Icon";
import _ from "underscore";

// Styled
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "48px",
  padding: "13px 24px",
  color: theme.palette.neutral["white"],
  backgroundColor: theme.palette.green.main,
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.green.hover,
  },
}));
export const RoundButton = (props) => {
  const { height, width, text, link } = props;
  const hasLink = _(props).has("link");

  return (
    <>
      {!!hasLink ? (
        <StyledButton
          {...props}
          href={link ? link : null}
          name={text + "-button"}
          sx={{
            height: height ? height : "46px",
            width: width ? width : "155px",
          }}
        >
          <Typography variant="roundButton">{text}</Typography>
        </StyledButton>
      ) : (
        <StyledButton
          {...props}
          name={text + "-button"}
          sx={{
            height: height ? height : "46px",
            width: width ? width : "155px",
          }}
        >
          <Typography variant="roundButton">{text}</Typography>
        </StyledButton>
      )}
    </>
  );
};
const StyledNavButton = styled(Button)(({ theme }) => ({
  padding: "0px 24px",
  color: theme.palette.ink["50"],
  backgroundColor: theme.palette.ink["300"],
  borderBottom: "solid",
  borderBottomWidth: "thin",
  borderBottomColor: theme.palette.ink["300"],
  borderRadius: 0,
  "&:hover": {
    backgroundColor: theme.palette.ink["300"],
    color: theme.palette.gray.main,
    borderBottomColor: theme.palette.green.main,
  },
  "&:active": {
    backgroundColor: theme.palette.ink["300"],
    color: theme.palette.gray.main,
    borderBottomColor: theme.palette.green.main,
  },
  "&:focus": {
    backgroundColor: theme.palette.ink["300"],
    color: theme.palette.gray.main,
    borderBottomColor: theme.palette.ink["300"],
  },
}));

/**
 *
 * @component
 * @module NavButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} props.text button text.
 * @param {Boolean} props.endIcon shows icon for dropdown buttons.
 * @param {String} props.link address for button to navigate to.
 * @description
 * Navigation Button
 * @example <caption>Usage:</caption>
 * return(
 *   <NavButton
 *     text="BoxC"
 *     endIcon={false}
 *     link="http://boxc.com/about"
 *   />
 * )
 */
export const NavButton = (props) => {
  const { text, endIcon, link } = props;

  return (
    <StyledNavButton
      {...props}
      disableRipple
      disableFocusRipple
      disableElevation
      endIcon={
        endIcon && (
          <ChevronDownIcon
            color="inherit"
            sx={{ marginTop: "5px", height: "20px" }}
          />
        )
      }
      href={
        link
          ? link
          : endIcon
          ? null
          : "/" + text.toString().toLowerCase().replace(/\s+/g, "-")
      }
      target={link ? "_blank" : "_self"}
      sx={{ height: "100%" }}
    >
      <Mobile>
        <Typography variant="body2">{text}</Typography>
      </Mobile>
      <Desktop>
        {endIcon ? (
          <Typography variant="body1">{text}</Typography>
        ) : (
          <Typography color="inherit" variant="body1" height="100%">
            {text}
          </Typography>
        )}
      </Desktop>
    </StyledNavButton>
  );
};
/**
 *
 * @component
 * @module NavMenuButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} props.text button text.
 * @param {Array} props.menuItems list of sub-menu items.
 * @description
 * Navigation Menu Button
 * @example <caption>Usage:</caption>
 * return(
 *   <NavMenuButton
 *     text=" *your menu button text* "
 *     menuItems={[ {name:" *your sub-menu button text* ",}, {name:" *another sub-menu button text* ",}, ]}
 *   />
 * )
 */
export const NavMenuButton = (props) => {
  const { text, menuItems } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickAway = () => {
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <>
        <NavButton
          id={"nav-menu-button-" + text}
          text={text}
          endIcon={true}
          aria-controls={open ? text + "-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        />
        <Menu
          id={text + "-menu"}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableAutoFocusItem={true}
          MenuListProps={{
            "aria-labelledby": text + "-menu",
            disableListWrap: true,
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "ink.300",
              width: "242px",
            },
          }}
        >
          {menuItems &&
            menuItems.map((submenuitem) => {
              const { name, link } = submenuitem;
              const uri = name.toString().toLowerCase().replace(/\s+/g, "-");

              return (
                <MenuItem
                  key={"sub-nav-menu-button-" + uri}
                  onClick={handleClose}
                >
                  <Link
                    variant="body1"
                    underline="none"
                    href={link ? link : "/" + uri}
                    target={link ? "_blank" : "_self"}
                    sx={{
                      color: "black.light",
                      "&:hover": {
                        color: "gray.main",
                      },
                    }}
                  >
                    {name}
                  </Link>
                </MenuItem>
              );
            })}
        </Menu>
      </>
    </ClickAwayListener>
  );
};
/**
 *
 * @component
 * @module GetStarted
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Number} height height of the button
 * @param {Number} width width of the button
 * @description
 * Get Started Button
 * @example <caption>Usage:</caption>
 * return(
 *   <GetStarted width="auto" />
 * )
 */
export const GetStarted = ({ height, width }) => {
  return (
    <RoundButton
      height={height}
      width={width}
      text="Get Started"
      link="https://accounts.boxc.com/register?trk=home-get-started"
      target="_blank"
    />
  );
};
/**
 *
 * @component
 * @module HowItWorksButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Number} height height of the button
 * @param {Number} width width of the button
 * @description
 * How It Works Button
 * @example <caption>Usage:</caption>
 * return(
 *   <HowItWorksButton width="auto" />
 * )
 */
export const HowItWorksButton = ({ height, width }) => {
  return (
    <RoundButton
      height={height}
      width={width}
      text="How it Works"
      link="/how-it-works"
    />
  );
};
/**
 *
 * @component
 * @module RequestInfoButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Number} height height of the button
 * @param {Number} width width of the button
 * @description
 * Request More Information Button
 * @example <caption>Usage:</caption>
 * return(
 *   <RequestInfoButton width="auto" />
 * )
 */
export const PreFooterButton = ({ text, href, height, width }) => {
  return (
    <RoundButton
      height={height}
      width={width}
      text={text}
      link="/request-more-info"
    />
  );
};
/**
 *
 * @component
 * @module SolutionsButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Number} height height of the button
 * @param {Number} width width of the button
 * @param {String} text text to be displayed on the button
 * @param {String} link post-button-click navigation address
 * @description
 * Solutions Button
 * @example <caption>Usage:</caption>
 * return(
 *   <SolutionsButton text="Logistics" width="auto" link="/logistics" />
 * )
 * @example
 * return(
 *   <SolutionsButton text="Solutions" width="auto" link="/solutions" />
 * )
 */
export const SolutionsButton = ({ height, width, text, link }) => {
  return <RoundButton height={height} width={width} text={text} link={link} />;
};
/**
 *
 * @component
 * @module FormButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} props.text text to be displayed on the button
 * @description
 * Form Button
 * @example <caption>Usage:</caption>
 * return(
 *   <FormButton text="Submit" type="submit" width="min-content"/>
 * )
 */
export const FormButton = (props) => {
  const { text } = props;

  return <RoundButton {...props} text={text} />;
};
/**
 *
 * @component
 * @module TrackingButton
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props button props to be passed to Tracking Button
 * @description
 * Tracking Button
 * @example <caption>Usage:</caption>
 * return(
 *   <TrackingButton type="submit" width="auto"/>
 * )
 */
export const TrackingButton = (props) => {
  return <RoundButton {...props} text="Track" />;
};
