import Hero from "../../components/Hero";
import { Box, Typography } from "@mui/material";
import useStrapi from "../../hooks/useStrapi";
import { Img } from "../../components/Image";
import { useEffect, useRef, useState } from "react";

export const HomeHero = () => {
  const { headers, fetchHeaders } = useStrapi();
  const isLoading = useRef(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (headers === null && !isLoading.current) {
      isLoading.current = true;
      fetchHeaders();
    } else {
      isLoading.current = false;

      if (data === null) {
        if (!!headers && "home" in headers && headers["home"] != null) {
          setData(headers["home"]);
        }
      }
    }
  }, [headers, fetchHeaders, data]);

  return (
    <Hero
      title={
        <Box mt={{ xs: 10, lg: 20 }} ml={{ xs: 2, lg: 10 }}>
          {!!data && (
            <Typography
              variant={data["title"]["font"]}
              color={
                data["title"]["color"].toLowerCase() === "light"
                  ? "gray.main"
                  : "black.main"
              }
            >
              {data["title"]["value"]}
            </Typography>
          )}
        </Box>
      }
      subTitle={
        <Box ml={{ xs: 2, lg: 10 }}>
          {!!data && (
            <Typography
              variant={data["subTitle"]["font"]}
              color={
                data["subTitle"]["color"].toLowerCase() === "light"
                  ? "black.light"
                  : "black.main"
              }
            >
              {data["subTitle"]["value"]}
            </Typography>
          )}
        </Box>
      }
      image={
        <>
          {!!data && (
            <Img
              maxHeight="606px"
              maxWidth="924px"
              mx="auto"
              src={data["image"]["data"]["attributes"]["url"]}
              alt="home-hero-img"
            />
          )}
        </>
      }
      doTransitions={Boolean(!!data)}
    />
  );
};
