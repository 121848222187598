import { Grid } from "@mui/material";
import ColumnCard from "../../components/ColumnCard";
import MediaCard from "../../components/MediaCard";

const WhyBoxC = () => {
  return (
    <Grid
      container
      item
      maxWidth={1440}
      sx={{
        mx: "auto",
        px: { xs: 2, lg: 10 },
        pb: { xs: 5, lg: 10 },
        pt: { xs: 5, lg: 20 },
      }}
    >
      <MediaCard
        page="home"
        index={0}
        sizes={[5, 7]}
        imageConfig={{
          height: "auto",
          maxWidth: 792,
          mx: "auto",
        }}
      />
      <ColumnCard
        page="home"
        index={0}
        direction="row"
        spacing={{
          xs: 5,
          lg: 8,
        }}
        pt={{
          xs: 5,
          lg: 10,
        }}
        columnConfig={{
          spacing: 1,
          textAlign: "left",
        }}
      />
    </Grid>
  );
};

export default WhyBoxC;
