import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormButton } from "../../components/Button";
import { countries } from "../../utils/countries";
import { categories } from "../../utils/categories";
import { quantities } from "../../utils/quantities";
import axios from "axios";

const Form = (props) => {
  const navigate = useNavigate();
  const handleFormSubmission = (values) => {
    try {
      /* Axios POST request to existing mail service */
      axios
        .post("/mail/request-more-info", values)
        .then((response) => {
          navigate("/thank-you");
          // alert(JSON.stringify(response, null, 2));
        })
        .catch((e) => alert(e.name.toString() + ":\n" + e.message.toString()));
    } catch (e) {
      alert(e);
    }
  };
  const validationSchema = yup.object({
    name: yup.string("Enter your name").required("Your name is required"),
    company: yup
      .string("Enter your company name")
      .required("Company name is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    industries: yup.array().required("Selection is required..."),
    locations: yup.array().required("Selection is required..."),
    products: yup.array().required("Selection is required..."),
    quantity: yup
      .string("Please select your average quantity of shipments per month")
      .required("Selection is required..."),
    batteries: yup.string().required(),
    liquidsPowders: yup.string().required(),
  });
  const [formValues] = useState({
    name: "",
    company: "",
    email: "",
    industries: [""],
    locations: [""],
    products: [""],
    quantity: "",
    batteries: "no",
    liquidsPowders: "no",
  });
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmission,
  });

  return (
    <Box {...props}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={5} width={{ xs: 400, lg: 500 }}>
          {/* name */}
          <FormControl>
            <FormLabel id="label-name">Name</FormLabel>
            <>
              <TextField
                id="name"
                name="name"
                fullWidth
                variant="outlined"
                placeholder="Required"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <FormHelperText>Please enter your full name.</FormHelperText>
              )}
            </>
          </FormControl>
          {/* company */}
          <FormControl>
            <FormLabel id="label-company">Company</FormLabel>
            <>
              <TextField
                id="company"
                name="company"
                fullWidth
                variant="outlined"
                placeholder="Required"
                value={formik.values.company}
                onChange={formik.handleChange}
                error={formik.touched.company && Boolean(formik.errors.company)}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
              />
              {formik.touched.company && formik.errors.company && (
                <FormHelperText>Please enter your company name.</FormHelperText>
              )}
            </>
          </FormControl>
          {/* email */}
          <FormControl>
            <FormLabel id="label-email">Email</FormLabel>
            <>
              <TextField
                id="email"
                name="email"
                fullWidth
                variant="outlined"
                placeholder="Required"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText>
                  Please enter your company email.
                </FormHelperText>
              )}
            </>
          </FormControl>
          {/* company categories */}
          <FormControl>
            <FormLabel id="label-companyCategories">
              What are your company's industry categories?
            </FormLabel>
            <>
              <Select
                id="industries"
                name="industries"
                displayEmpty
                fullWidth
                variant="outlined"
                multiple
                value={formik.values.industries}
                onChange={formik.handleChange}
                error={
                  formik.touched.industries && Boolean(formik.errors.industries)
                }
                renderValue={(selected) => {
                  if (selected.length === 1 && selected[0] === "") {
                    return (
                      <Typography sx={{ color: "ink.50" }}>Required</Typography>
                    );
                  }

                  return selected.slice(1).join(", ");
                }}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
                MenuProps={{
                  elevation: 1,
                }}
              >
                <MenuItem value="" disabled>
                  Required
                </MenuItem>
                <MenuItem value="Airline">Airline</MenuItem>
                <MenuItem value="Courier">Courier</MenuItem>
                <MenuItem value="E-commerce Retailer">
                  E-commerce Retailer
                </MenuItem>
                <MenuItem value="Freight Forwarder">Freight Forwarder</MenuItem>
                <MenuItem value="Technology">Technology</MenuItem>
                <MenuItem value="Logistics & Transportation">
                  Logistics & Transportation
                </MenuItem>
                <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                <MenuItem value="Online Marketplace">
                  Online Marketplace
                </MenuItem>
                <MenuItem value="Postal">Postal</MenuItem>
                <MenuItem value="Third-Party Logistics">
                  Third-Party Logistics
                </MenuItem>
                <MenuItem value="Warehousing & Distribution">
                  Warehousing & Distribution
                </MenuItem>
                <MenuItem value="Wholesale">Wholesale</MenuItem>
              </Select>
              {formik.touched.industries && formik.errors.industries && (
                <FormHelperText>
                  Please make necessary selections.
                </FormHelperText>
              )}
            </>
          </FormControl>
          {/* shipping from */}
          <FormControl>
            <FormLabel id="label-shipLocations">
              What countries are you shipping from?
            </FormLabel>
            <>
              <Select
                id="locations"
                name="locations"
                displayEmpty
                fullWidth
                variant="outlined"
                multiple
                value={formik.values.locations}
                onChange={formik.handleChange}
                error={
                  formik.touched.locations && Boolean(formik.errors.locations)
                }
                renderValue={(selected) => {
                  if (selected.length === 1 && selected[0] === "") {
                    return (
                      <Typography sx={{ color: "ink.50" }}>Required</Typography>
                    );
                  }

                  return selected.slice(1).join(", ");
                }}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
                MenuProps={{
                  elevation: 1,
                }}
              >
                <MenuItem value="" disabled>
                  Required
                </MenuItem>
                {countries.map((country) => {
                  return (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.locations && formik.errors.locations && (
                <FormHelperText>
                  Please make necessary selections.
                </FormHelperText>
              )}
            </>
          </FormControl>
          {/* product categories */}
          <FormControl>
            <FormLabel id="label-products">
              What product categories are you shipping?
            </FormLabel>
            <>
              <Select
                id="products"
                name="products"
                displayEmpty
                fullWidth
                variant="outlined"
                multiple
                value={formik.values.products}
                onChange={formik.handleChange}
                error={
                  formik.touched.products && Boolean(formik.errors.products)
                }
                renderValue={(selected) => {
                  if (selected.length === 1 && selected[0] === "") {
                    return (
                      <Typography sx={{ color: "ink.50" }}>Required</Typography>
                    );
                  }

                  return selected.slice(1).join(", ");
                }}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
                MenuProps={{
                  elevation: 1,
                }}
              >
                <MenuItem value="" disabled>
                  Required
                </MenuItem>
                {categories.map((category) => {
                  return (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.products && formik.errors.products && (
                <FormHelperText>
                  Please make necessary selections.
                </FormHelperText>
              )}
            </>
          </FormControl>
          {/* quantity */}
          <FormControl>
            <FormLabel id="label-quantity">
              Average quantity of shipments per month?
            </FormLabel>
            <>
              <Select
                id="quantity"
                name="quantity"
                displayEmpty
                fullWidth
                variant="outlined"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.quantity && Boolean(formik.errors.quantity)
                }
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <Typography sx={{ color: "ink.50" }}>Required</Typography>
                    );
                  }

                  return selected;
                }}
                sx={{
                  backgroundColor: "neutral.white",
                  borderColor: "ink.50",
                  borderRadius: "4px",
                }}
                MenuProps={{
                  elevation: 1,
                }}
              >
                <MenuItem value="" disabled>
                  Required
                </MenuItem>
                {quantities.map((quantity) => {
                  return (
                    <MenuItem key={quantity} value={quantity}>
                      {quantity}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.quantity && formik.errors.quantity && (
                <FormHelperText>
                  Please make necessary selections.
                </FormHelperText>
              )}
            </>
          </FormControl>
          {/* batteries */}
          <FormControl role="group">
            <FormLabel id="label-batteries">
              Do shipments contain batteries?
            </FormLabel>
            <RadioGroup
              aria-labelledby="label-batteries"
              onChange={formik.handleChange}
              defaultValue="no"
            >
              <FormControlLabel
                name="batteries"
                value="yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                name="batteries"
                value="no"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {formik.touched.batteries && formik.errors.batteries && (
              <FormHelperText>{formik.errors.batteries}</FormHelperText>
            )}
          </FormControl>
          {/* liquids or powders */}
          <FormControl role="group">
            <FormLabel id="label-liquidsPowders">
              Do shipments contain liquids or powders?
            </FormLabel>
            <RadioGroup
              aria-labelledby="label-liquidsPowders"
              onChange={formik.handleChange}
              defaultValue="no"
            >
              <FormControlLabel
                name="liquidsPowders"
                value="yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                name="liquidsPowders"
                value="no"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {formik.touched.liquidsPowders && formik.errors.liquidsPowders && (
              <FormHelperText>{formik.errors.liquidsPowders}</FormHelperText>
            )}
          </FormControl>
          {/* submit */}
          <FormButton text="Submit" type="submit" width="min-content" />
        </Stack>
      </form>
    </Box>
  );
};

export default Form;
