import { Box, Grid, Stack, Typography } from "@mui/material";
import { RoundButton } from "../../components/Button";
import ErrorHero from "./ErrorHero";
import { ErrorHelmet } from "../../components/MetaHelmet";

const Error = (props) => {
  const { message } = props;

  return (
    <Grid container item xs={12}>
      <ErrorHelmet />
      <ErrorHero />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        my={20}
      >
        <Stack direction="column" spacing={10}>
          <Typography variant="h1" color="error.main">
            Oops!
          </Typography>
          <Typography variant="h3" color="black.main">
            Message: {message}
          </Typography>
          <RoundButton text="Back to Home" link="/" />
        </Stack>
      </Box>
    </Grid>
  );
};

export default Error;
