import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { SolutionsButton } from "./Button";
import { Desktop } from "../layout";
import { Img } from "./Image";
import useStrapi from "../hooks/useStrapi";

/**
 * @component
 * @module SolutionCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props property object passed to component
 * @param {String} props.name unique identifier for retrieving component from fetched Strapi collection
 * @description
 * Solution Card Component
 * @example <caption>Usage:</caption>
 * return(
 *   <SolutionCard name="your-solution-card-name" />
 * )
 * @return {JSX.Element}
 */
const SolutionCard = (props) => {
  const { name } = props;
  const { solutionCards, fetchSolutionCards, fetchingSolutionCards } =
    useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (solutionCards === null && !fetchingSolutionCards.current) {
      fetchSolutionCards();
    } else {
      if (!!solutionCards && data === null) {
        const card = solutionCards.find((card) => card.name === name);

        setData(card != null ? card : null);
      }
    }
  }, [solutionCards, fetchSolutionCards, fetchingSolutionCards, data, name]);

  return (
    <>
      {!!data && (
        <Grid container item>
          <Grid container item px={{ xs: 0, lg: 10 }} maxWidth={1440} mx="auto">
            {/* DIAGONAL TITLE BAR */}
            <Grid item xs={12}>
              <Desktop>
                <Box
                  sx={{
                    position: "relative",
                    left: 550,
                    top: 30,
                    height: 57,
                    width: 180,
                    transform: "matrix(0.96, 0.25, -0.27, 0.97, 0, 0)",
                    backgroundColor: "lavender.main",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "16px, 41px, 16px, 41px",
                  }}
                >
                  <Typography
                    variant={data["title"]["font"]}
                    color={
                      data["title"]["color"] === "dark"
                        ? "black.main"
                        : "gray.main"
                    }
                    textAlign="center"
                  >
                    {data["title"]["value"]}
                  </Typography>
                </Box>
              </Desktop>
            </Grid>
            {/* LEFT SIDE */}
            <Grid
              item
              xs={12}
              lg={6}
              zeroMinWidth
              sx={{ backgroundColor: "#21232A" }}
            >
              <Box>
                <Stack alignItems="center" rowGap={2}>
                  {/* TITLE */}
                  <Box mt="63px" mx={{ lg: "48px" }}>
                    <Typography
                      variant={data["leftSide"]["title"]["font"]}
                      color={
                        data["leftSide"]["title"]["color"] === "light"
                          ? "neutral.white"
                          : "black.main"
                      }
                    >
                      {data["leftSide"]["title"]["value"]}
                    </Typography>
                  </Box>
                  {/* SUBTITLE */}
                  <Typography
                    variant={data["leftSide"]["subTitle"]["font"]}
                    color={
                      data["leftSide"]["subTitle"]["color"] === "light"
                        ? "black.light"
                        : "black.main"
                    }
                    pb={4}
                    px={{ xs: 5, lg: 20 }}
                    textAlign="center"
                  >
                    {data["leftSide"]["subTitle"]["value"]}
                  </Typography>
                  {/* BUTTON */}
                  <SolutionsButton
                    text={`Solutions for ${data["leftSide"]["title"]["value"]}`}
                    width="auto"
                    link={data["leftSide"]["href"]}
                  />
                  {/* IMAGE */}
                  <Img
                    src={data["leftSide"]["image"]}
                    alt="first-solution-img"
                    maxHeight="406px"
                    maxWidth="414px"
                    mx={{ xs: 2, lg: "auto" }}
                    pt={{ xs: 6, lg: 12 }}
                    pb={{ xs: 2, lg: 10 }}
                  />
                </Stack>
              </Box>
            </Grid>
            {/* RIGHT SIDE */}
            <Grid
              item
              xs={12}
              lg={6}
              zeroMinWidth
              sx={{ backgroundColor: "black.dark" }}
            >
              <Box>
                <Stack alignItems="center" rowGap={2}>
                  {/* TITLE */}
                  <Box mt="63px" mx={{ lg: "48px" }}>
                    <Typography
                      variant={data["rightSide"]["title"]["font"]}
                      color={
                        data["rightSide"]["title"]["color"] === "light"
                          ? "neutral.white"
                          : "black.main"
                      }
                    >
                      {data["rightSide"]["title"]["value"]}
                    </Typography>
                  </Box>
                  {/* SUBTITLE */}
                  <Typography
                    variant={data["rightSide"]["subTitle"]["font"]}
                    color={
                      data["rightSide"]["subTitle"]["color"] === "light"
                        ? "black.light"
                        : "black.main"
                    }
                    pb={4}
                    px={{ xs: 8, lg: 25 }}
                    textAlign="center"
                  >
                    {data["rightSide"]["subTitle"]["value"]}
                  </Typography>
                  {/* BUTTON */}
                  <SolutionsButton
                    text={`Solutions for ${data["rightSide"]["title"]["value"]}`}
                    width="auto"
                    link={data["rightSide"]["href"]}
                  />
                  {/* IMAGE */}
                  <Img
                    src={data["rightSide"]["image"]}
                    alt="second-solution-img"
                    maxHeight="406px"
                    maxWidth="414px"
                    mx={{ xs: 2, lg: "auto" }}
                    pt={12}
                    pb={2}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SolutionCard;
