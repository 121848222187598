import { Accordion, AccordionDetails, Box, Grid, Link } from "@mui/material";
import { NavLogo } from "./Logo";

/**
 * @component
 * @category Client
 * @subcategory Components
 * @module LogoBar
 * @param {Boolean} usePackageTrackingReset used for deciding BoxC logo's navigation responsibility post-click (either: reset tracking or no action).
 * @description
 * AppBar utility intended to assist Tracking page functionality
 * @example <caption>Usage:</caption>
 * return(
 *   <LogoBar usePackageTrackingReset />
 * )
 */
const LogoBar = ({ usePackageTrackingReset }) => {
  return (
    <Grid container item xs={12} sx={{ backgroundColor: "ink.300" }}>
      <Grid item flexGrow={1} maxWidth={1440} mx="auto">
        <Accordion sx={{ backgroundColor: "ink.300" }}>
          <AccordionDetails sx={{ p: 0 }}>
            {usePackageTrackingReset ? (
              <Link underline="none" href="/track">
                <NavLogo />
              </Link>
            ) : (
              <Box>
                <NavLogo />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default LogoBar;
