import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { TrackingButton } from "../../components/Button";
import Bottom from "../../components/Bottom";
import axios from "axios";
import { Desktop, Mobile } from "../../layout";
import { CircleExceptionIcon } from "../../components/Icon";
import { useLocation, useParams } from "react-router-dom";
import { TrackHelmet } from "../../components/MetaHelmet";
import { Validation } from "./Validation";
import FAQ from "./FAQ";
import Status from "./Status";
import TimelineTracker from "./timeline";
import LogoBar from "../../components/LogoBar";
import _ from "underscore";

/**
 * Track
 *
 * @component
 * @module Track
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/track" element={<Track />} />
 *       <Route exact path="/h/track" element={<Track />} />
 *       <Route path="/track/:trackingNumber" element={<Track />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Tracking Page
 *
 * Gives the user visibility on tracking events for a searched package.
 */
const Track = () => {
  const location = useLocation();
  const params = useParams();
  const validationSchema = Validation();
  const [track, setTrack] = useState(null);
  const [tracked, setTracked] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [trackingError, setTrackingError] = useState(null);
  const handleChange = (event) => {
    const { value } = event.target;

    setTrackingNumber(value);
  };
  const handleFormSubmission = async (values) => {
    values.trackingNumber = values.trackingNumber.trim();
    await axios
      .post("/api/track", values)
      .then((response) => {
        if (response.status === 200 && !response.data.success) {
          const track = response.data;
          const events = track.events;
          track.status = {
            date: events[0].time,
            city: events[0].city,
            province: events[0].province,
            country: "country" in events[0] ? events[0].country : "",
            postalCode: events[0].postal_code,
            color:
              track.status === "Exception"
                ? "#FF0000"
                : track.status === "Delivered"
                ? "#2E8049"
                : "#E5A830",
          };

          setTrack(track);
          setTrackingNumber(track.tracking_number);
          setTrackingError(null);
          setTracked(true);
        } else {
          setTrackingError(
            "We had an issue fetching your tracking information..."
          );
        }
      })
      .catch((e) => {
        setTrackingError("Could not retrieve tracking information...");
      });
  };

  useEffect(() => {
    if (trackingNumber === null) {
      let _trackingNumber;

      if (!!location.search && location.search !== "") {
        // google analytics
        if (location.search.includes("?_gl=")) {
          // boxc.com/track/?id=some_tracking_number
          if (location.search.includes("?id=")) {
            _trackingNumber = location.search.substring(
              location.search.indexOf("?=") + 2,
              location.search.indexOf("?_gl=")
            );
          }
          // boxc.com/track?=some_tracking_number
          else {
            _trackingNumber = location.search.substring(
              location.search.indexOf("?=") + 2,
              location.search.indexOf("?_gl=")
            );
          }
        }
        // boxc.com/track/?id=some_tracking_number
        else if (location.search.includes("?id=")) {
          _trackingNumber = location.search.split("?id=")[1];
        }
        // boxc.com/track?=some_tracking_number
        else {
          _trackingNumber = location.search.split("?=")[1];
        }
      }
      // boxc.com/track/some_tracking_number
      else if (_(params).has("trackingNumber")) {
        _trackingNumber = params["trackingNumber"];
      }
      // boxc.com/track
      else {
        _trackingNumber = "";
      }

      setTrackingNumber(_trackingNumber);
    }
  }, [trackingNumber, location.pathname, params, location.search]);

  return (
    <Grid container item xs={12} zeroMinWidth>
      <TrackHelmet />
      {/*<Navigation />*/}
      <LogoBar usePackageTrackingReset />
      {/* header */}
      <Grid item xs={12} bgcolor="black.main" pl={{ xs: 2, lg: 25 }} py={5}>
        <Box maxWidth={1440} mx="auto">
          <Typography variant="xlTitle" color="gray.main">
            Tracking
          </Typography>
        </Box>
      </Grid>
      {/* form */}
      <Grid item flexGrow={1} xs={12} mx={{ xs: 2 }}>
        {trackingNumber !== null && (
          <Box maxWidth={650} mx="auto" mt={{ xs: 5, lg: 10 }}>
            <Formik
              initialValues={{
                trackingNumber: trackingNumber,
              }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmission}
              onChange={handleChange}
            >
              {(props) => (
                <Form>
                  <FormControl
                    fullWidth
                    sx={{ display: "flex", flexDirection: "row", columnGap: 2 }}
                  >
                    <TextField
                      id="trackingNumber"
                      name="trackingNumber"
                      fullWidth
                      variant="outlined"
                      label="Tracking Number"
                      value={props.values.trackingNumber}
                      inputProps={{ minLength: 8, maxLength: 40 }}
                      onChange={props.handleChange}
                      error={
                        props.touched.trackingNumber &&
                        Boolean(props.errors.trackingNumber)
                      }
                      sx={{
                        backgroundColor: "neutral.white",
                        borderColor: "ink.50",
                        borderRadius: "4px",
                      }}
                    />
                    {props.touched.trackingNumber &&
                      props.errors.trackingNumber && (
                        <FormHelperText>
                          Please enter a valid tracking number.
                        </FormHelperText>
                      )}
                    <TrackingButton type="submit" width="auto" />
                  </FormControl>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </Grid>
      {/* tracking info */}
      <Grid container item xs={12}>
        {trackingError !== null && (
          <Grid item maxWidth={1440} mx="auto">
            <Box px={{ xs: 2, lg: 40 }} my={{ xs: 5, lg: 10 }}>
              <Paper
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                elevation={5}
                sx={{
                  borderRadius: 5,
                }}
              >
                <Stack
                  direction="column"
                  rowGap={5}
                  width="fit-content"
                  mx="auto"
                  p={5}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <CircleExceptionIcon />
                    <Typography variant="h2" sx={{ color: "#FF0000" }}>
                      Error
                    </Typography>
                  </Stack>
                  <Typography variant="h3">{trackingError}</Typography>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ borderRadius: "48px" }}
                    onClick={() => {
                      setTrackingNumber("");
                      setTrackingError(null);
                      setTracked(false);
                      setTrack({});
                    }}
                  >
                    OK
                  </Button>
                </Stack>
              </Paper>
            </Box>
          </Grid>
        )}
        {!trackingError && !tracked && <FAQ />}
        {!trackingError && tracked && (
          <Grid container item maxWidth={1440} mx="auto">
            <Grid item xs={12}>
              <Grid item textAlign="center" mt={10}>
                <Desktop>
                  <Typography variant="xlTitle">{trackingNumber}</Typography>
                </Desktop>
                <Mobile>
                  <Typography variant="h1">{trackingNumber}</Typography>
                </Mobile>
              </Grid>
              <Grid item pb={10}>
                <Status status={track.status} />
                <TimelineTracker trackerEvents={track.events} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Bottom showPrivacy />
    </Grid>
  );
};

export default Track;
