import { Grid, Stack, Typography } from "@mui/material";
import _ from "underscore";
import { Img } from "./Image";
import useStrapi from "../hooks/useStrapi";
import { useEffect, useState } from "react";

/**
 *
 * @component
 * @module MediaCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props property object passed to component
 * @param {string} props.page - the page containing this media card (example: "home")
 * @param {number} props.index - the index of the media card on your page (0 indexed)
 * @param {number[]} props.sizes left and right column sizes
 * @param {Object} props.imageConfig image configuration object
 * @description
 * Strapi media-card component
 * @example <caption>Usage:</caption>
 * return(
 *   <MediaCard
 *     page="example-page"
 *     index={0}
 *     sizes={[5,7]}
 *     imageConfig={{
 *       height: "auto",
 *       maxWidth: 500,
 *       mx: "auto",
 *     }}
 *   />
 * )
 */
const MediaCard = (props) => {
  const { page, index, sizes, imageConfig } = props;
  const { mediaCards, fetchMediaCards, fetchingMediaCards } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (mediaCards == null && !fetchingMediaCards.current) {
      fetchMediaCards();
    } else {
      if (!!mediaCards) {
        const card = mediaCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : false);
      }
    }
  }, [page, index, mediaCards, fetchMediaCards, fetchingMediaCards, data]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} lg={sizes[0]} zeroMinWidth justifyContent="flex-start">
        <Stack
          rowGap={6}
          direction="column"
          textAlign="left"
          py={{ xs: 5, lg: 10 }}
        >
          {!!data && _(data).has("title") && (
            <Typography
              variant={data["title"]["font"]}
              color={
                data["title"]["color"].toLowerCase() === "dark"
                  ? "black.main"
                  : "gray.main"
              }
            >
              {data["title"]["value"]}
            </Typography>
          )}
          {!!data && _(data).has("content") && (
            <>
              {Array.isArray(data["content"]["value"]) ? (
                <>
                  {data["content"]["value"].map((content, index) => {
                    return (
                      <Typography
                        key={`media-card-${data["id"]}-paragraph-${index}`}
                        variant={data["content"]["font"]}
                        color="ink.200"
                      >
                        {content}
                      </Typography>
                    );
                  })}
                </>
              ) : (
                <Typography variant={data["content"]["font"]} color="ink.200">
                  {data["content"]["value"]}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={sizes[1]}
        zeroMinWidth
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {!!data && _(data).has("image") && (
          <Img src={data["image"]} alt={data["image"]} {...imageConfig} />
        )}
      </Grid>
    </Grid>
  );
};

export default MediaCard;
