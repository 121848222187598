import { useEffect } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Bottom from "../../components/Bottom";
import Navigation from "../../components/Navigation";
import { PivacyHelmet } from "../../components/MetaHelmet";
import useStrapi from "../../hooks/useStrapi";

/**
 * @component
 * @module PrivacyPolicy
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/privacy" element={<PrivacyPolicy />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Privacy Policy Page
 */
const PrivacyPolicy = () => {
  const { privacy, fetchPrivacy, fetchingPrivacy } = useStrapi();

  useEffect(() => {
    if (privacy === null && !fetchingPrivacy.current) {
      fetchPrivacy();
    }
  }, [privacy, fetchPrivacy, fetchingPrivacy]);

  return (
    <Grid container item xs={12}>
      <Navigation />
      <PivacyHelmet />
      {!!privacy && (
        <>
          <Grid item xs={12} bgcolor="black.main" pl={{ xs: 2, lg: 25 }} py={5}>
            <Box maxWidth={1440} mx="auto">
              <Typography variant="xlTitle" color="gray.main">
                {privacy["title"]}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            py={{ xs: "inherit", lg: 8 }}
            px={{ xs: 2, lg: 9 }}
          >
            <Box maxWidth={1440} mx="auto">
              <Stack direction="column" flexGrow={1} mx={{ xs: 2, lg: 10 }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    mt: 5,
                  }}
                >
                  <Typography variant="h5" color="ink.100">
                    {privacy["date"]}
                  </Typography>
                </Box>
                {!!privacy &&
                  privacy["content"].map((paragraph) => {
                    const content = paragraph.split("\n");
                    const paragraphTitle =
                      content[0] !== "" && content[0] !== undefined
                        ? content[0]
                        : content[1];
                    const paragraphContent = content.slice(
                      content.indexOf(paragraphTitle) + 1,
                      content.length
                    );

                    return (
                      <Stack key={Math.random() * 1000} direction="column">
                        <Typography variant="h3" color="black.main" py={1}>
                          {paragraphTitle}
                        </Typography>
                        {paragraphContent.map((data) => {
                          return (
                            <Typography
                              key={Math.random() * 1000}
                              variant="body2"
                              color="black.main"
                              paragraph
                            >
                              {data}
                            </Typography>
                          );
                        })}
                      </Stack>
                    );
                  })}
              </Stack>
            </Box>
          </Grid>
        </>
      )}
      <Bottom showNav />
    </Grid>
  );
};

export default PrivacyPolicy;
