import { useEffect } from "react";
import Bulletin from "./Bulletin";
import { Box, Grid, Typography } from "@mui/material";
import { RoundButton } from "./Button";
import useStrapi from "../hooks/useStrapi";

/**
 * @component
 * @module PreFooter
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @description
 * Pre-Footer Component
 * @example <caption>Usage:</caption>
 * return(
 *   <PreFooter />
 * )
 * @return {JSX.Element}
 */
const PreFooter = () => {
  const { preFooter, fetchPreFooter, fetchingPreFooter } = useStrapi();

  useEffect(() => {
    if (preFooter === null && !fetchingPreFooter.current) {
      fetchPreFooter();
    }
  }, [preFooter, fetchPreFooter, fetchingPreFooter]);

  return (
    <>
      {!!preFooter && (
        <Grid
          container
          item
          maxWidth={1440}
          mx="auto"
          px={{ xs: "inherit", lg: 10 }}
        >
          <Grid item xs={12} zeroMinWidth>
            <Box
              bgcolor="ink.300"
              top={{
                xs: "0px",
                lg: "80px",
              }}
              position="relative"
              pb="80px"
              pt={{ xs: "48px", lg: "80px" }}
              mx="auto"
              sx={{ wordWrap: "nowrap" }}
            >
              <Bulletin
                title={
                  <Typography
                    flexGrow={1}
                    textAlign="center"
                    variant={preFooter["title"]["font"]}
                    color={
                      preFooter["title"]["color"].toLowerCase() === "light"
                        ? "gray.main"
                        : "black.main"
                    }
                  >
                    {preFooter["title"]["value"]}
                  </Typography>
                }
                text={
                  <Box
                    textAlign="center"
                    maxWidth={{ xs: 500, lg: 1150 }}
                    mx="auto"
                    px={{ xs: "inherit", lg: 45 }}
                  >
                    <Typography
                      variant={preFooter["content"]["font"]}
                      color={
                        preFooter["content"]["color"].toLowerCase() === "light"
                          ? "black.light"
                          : "black.main"
                      }
                    >
                      {preFooter["content"]["value"]}
                    </Typography>
                  </Box>
                }
                button={
                  <>
                    {"button" in preFooter && (
                      <Box textAlign="center">
                        <RoundButton
                          width="auto"
                          text={preFooter["button"]["text"]["value"]}
                          link={preFooter["button"]["href"]}
                        />
                      </Box>
                    )}
                  </>
                }
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PreFooter;
