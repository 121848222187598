import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        mt: 10,
      }}
    >
      <CircularProgress color="primary" size="100px" />
    </Box>
  );
};

export default Loader;
