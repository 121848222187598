const components = {
  MuiContainer: {
    defaultProps: {
      disableGutters: true,
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
      square: true,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        "::before": {
          opacity: 0,
        },
      },
    },
  },
};

export default components;
