import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "../vendor/static/logo/Logo-Primary-Color-Dark.png";
import BackboardImg from "../vendor/static/logo/Logo-Primary-Color-Dark.png";
import FooterImg from "../vendor/static/logo/Logo-Secondary-Color-Dark.png";

// Navigation
const StyledLogoWrapper = styled(Paper)(({ theme }) => ({
  alignSelf: "flex-start",
  width: "250px",
  [theme.breakpoints.up("xs")]: {
    backgroundColor: theme.palette.ink["300"],
  },
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.ink["300"],
  },
  [theme.breakpoints.up("lg")]: {
    backgroundColor: theme.palette.black.main,
  },
}));
export const StyledLogo = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    ml: 0,
    padding: "18px 24px",
    height: 24,
    width: 102,
  },
  [theme.breakpoints.up("sm")]: {
    padding: "18px 24px",
    height: 24,
    width: 102,
  },
  [theme.breakpoints.up("lg")]: {
    padding: "22px 50px",
    height: 35,
    width: 152,
  },
}));
export const NavLogo = ({ imgSrc, height, width }) => {
  return (
    <StyledLogoWrapper
      width={width ? width : 252}
      height={height ? height : 80}
    >
      <StyledLogo
        component="img"
        src={imgSrc ? imgSrc.toString() : logo}
        alt="BoxC"
      />
    </StyledLogoWrapper>
  );
};

// Footer
const StyledFooterLogo = styled("img")({
  margin: "auto",
  display: "block",
  width: "100%",
});
export const FooterLogo = (props) => (
  <StyledFooterLogo src={FooterImg} {...props} />
);

// Backboard
const StyledBackboardWrapper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.black.main,
  color: theme.palette.neutral.white,
}));
const StyledBackboardLogo = styled(Box)({
  height: "48px",
  width: "206px",
  marginBottom: "1em",
});
export const BackboardLogo = (props) => {
  return (
    <StyledBackboardWrapper>
      <StyledBackboardLogo
        component="img"
        alt="BoxC Backboard"
        src={BackboardImg}
      />
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Open International Shipping Network
      </Typography>
    </StyledBackboardWrapper>
  );
};
