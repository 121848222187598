import { Grid } from "@mui/material";
import Bottom from "../../components/Bottom";
import SolutionCard from "../../components/SolutionCard";
import Summary from "./Summary";
import Navigation from "../../components/Navigation";
import { HowItWorksHelmet } from "../../components/MetaHelmet";
import { useRef } from "react";
import CallToAction from "../../components/CallToAction";
import ListMediaCard from "../../components/ListMediaCard";

/**
 * @component
 * @module HowItWorks
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/how-it-works" index element={<HowItWorks/>} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * How-It-Works Page
 */
const HowItWorks = () => {
  const scrollTo = useRef(null);
  const handleScroll = () =>
    scrollTo.current.scrollIntoView({ behavior: "smooth" });

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ backgroundColor: "black.main", color: "gray.main" }}
    >
      <HowItWorksHelmet />
      <Navigation />
      <CallToAction page="how-it-works" index={0} handleScroll={handleScroll} />
      <Summary ref={scrollTo} />
      {/* ONE API */}
      <ListMediaCard page="how-it-works" index={0} />
      {/* INHERENT MULTINATIONAL COMPLIANCE */}
      <ListMediaCard page="how-it-works" index={1} />
      {/* GLOBAL INFRASTRUCTURE */}
      <ListMediaCard page="how-it-works" index={2} />
      {/* PAY AS YOU GO */}
      <ListMediaCard page="how-it-works" index={3} lastCard />
      <SolutionCard name="logistics_ecommerce" />
      <Bottom showPreFooter showNav />
    </Grid>
  );
};

export default HowItWorks;
