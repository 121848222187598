import * as yup from "yup";

export const Validation = () =>
  yup.object({
    trackingNumber: yup
      .string()
      .trim()
      .required("Please enter a tracking number")
      .matches(
        /^[A-Z0-9]{8,40}$/i,
        "Tracking number must be alphanumeric and 8-40 characters."
      ),
  });
