import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import useStrapi from "../hooks/useStrapi";

const StatementCard = (props) => {
  const { page, index } = props;
  const { statementCards, fetchStatementCards, fetchingStatementCards } =
    useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (statementCards == null && !fetchingStatementCards.current) {
      fetchStatementCards();
    } else {
      if (!!statementCards) {
        const card = statementCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : null);
      }
    }
  }, [
    page,
    index,
    statementCards,
    fetchStatementCards,
    fetchingStatementCards,
    data,
  ]);

  return (
    <Grid container item flexGrow={1} justifyContent="center">
      <Grid
        container
        item
        maxWidth={1440}
        px={{ xs: 2, lg: 15 }}
        rowGap={2}
        pt={{ xs: 8, lg: 10 }}
        pb={{ xs: 8, lg: 10 }}
      >
        {data === null ? (
          <Grid item xs={12}>
            <Box
              sx={{
                height: "700px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} lg={7}>
              <Box>
                <Typography
                  variant={data["title"]["font"]}
                  color={
                    data["title"]["color"].toLowerCase() === "dark"
                      ? "black.main"
                      : "gray.main"
                  }
                >
                  {data["title"]["value"]}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box>
                <Typography
                  variant="h4"
                  color={
                    data["content"]["color"].toLowerCase() === "dark"
                      ? "black.main"
                      : "gray.main"
                  }
                >
                  {data["content"]["value"]}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default StatementCard;
