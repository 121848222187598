import { createTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import components from "./components";
import typography from "./typography";
import palette from "./palette";

const BOXC_THEME = createTheme({
  breakpoints: breakpoints,
  components: components,
  typography: typography,
  palette: palette,
});

export default BOXC_THEME;
