import { useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Img } from "./Image";
import ECommerceCard from "./ECommerceCard";

/**
 *
 * @component
 * @module ListMediaCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props {page, index, lastCard}
 * @param {string} props.page the page containing this component (example: "home")
 * @param {number} props.index the index of this component on your page (0 indexed)
 * @param {boolean} [props.lastCard] optional paramater that when true will add padding to the bottom of this component
 * @description
 * Strapi list-media-card component
 * @example <caption>Usage:</caption>
 * return(
 *   <ListMediaCard
 *      page="example-page"
 *      index={0}
 *      lastCard
 *   />
 * )
 */
const ListMediaCard = (props) => {
  const { page, index, lastCard } = props;
  const { listMediaCards, fetchListMediaCards, fetchingListMediaCards } =
    useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (listMediaCards == null && !fetchingListMediaCards.current) {
      fetchListMediaCards();
    } else {
      if (!!listMediaCards) {
        const card = listMediaCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : null);
      }
    }
  }, [
    page,
    index,
    listMediaCards,
    fetchListMediaCards,
    fetchingListMediaCards,
    data,
  ]);

  return (
    <>
      {!!data && (
        <>
          <Grid container item xs={12}>
            <Grid
              container
              maxWidth={1440}
              mx="auto"
              alignItems="center"
              px={{ xs: 2, sm: 5, lg: 25 }}
              pt={{ xs: "inherit", lg: 15 }}
              pb={{ xs: "inherit", lg: !!lastCard ? 15 : "inherit" }}
            >
              {/* IMAGE */}
              {data["mediaPlacement"].toLowerCase() === "left" && (
                <Grid item xs={12} lg={6}>
                  <Img
                    src={data["image"]}
                    alt={data["image"]}
                    maxHeight="500px"
                    maxWidth="500px"
                    mx="auto"
                  />
                </Grid>
              )}
              {/* CONTENT */}
              <Grid item xs={12} lg={6}>
                <Stack spacing={{ xs: 2, lg: 4 }} px={{ xs: 2, lg: 5 }}>
                  <Box>
                    <Typography
                      variant={data["body"]["title"]["font"]}
                      color={
                        data["body"]["title"]["color"].toLowerCase() === "dark"
                          ? "black.main"
                          : "gray.main"
                      }
                    >
                      {data["body"]["title"]["value"]}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={data["body"]["content"]["font"]}>
                      {data["body"]["content"]["value"]}
                    </Typography>
                  </Box>
                  {/* E-COMMERCE */}
                  <ECommerceCard statement={data["statement"]} />
                </Stack>
              </Grid>
              {/* IMAGE */}
              {data["mediaPlacement"].toLowerCase() === "right" && (
                <Grid item xs={12} lg={6}>
                  <Img
                    src={data["image"]}
                    alt={data["image"]}
                    maxHeight="500px"
                    maxWidth="500px"
                    mx="auto"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ListMediaCard;
