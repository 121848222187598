import useStrapi from "../hooks/useStrapi";
import { useEffect, useRef, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormButton } from "./Button";

const InfoCard = (props) => {
  const { page, index } = props;
  const { infoCards, fetchInfoCards } = useStrapi();
  const isLoading = useRef(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (infoCards == null && !isLoading.current) {
      fetchInfoCards();
    } else {
      if (!!infoCards) {
        const infoCard = infoCards.find(
          (_infoCard) =>
            _infoCard.page.name === page && _infoCard.index === index
        );

        setData(infoCard != null ? infoCard : false);
      }
    }
  }, [page, index, infoCards, fetchInfoCards, isLoading, data]);

  return (
    <>
      {!!data && (
        <Grid container item>
          <Grid
            container
            item
            justifyContent="center"
            mx="auto"
            maxWidth={1440}
          >
            {/* LEFT */}
            <Grid
              container
              item
              xs={12}
              lg={6}
              zeroMinWidth
              alignItems="flex-start"
              justifyContent="flex-start"
              pl={{ xs: 2, lg: 10 }}
            >
              <Box mt={{ xs: 9, lg: 20 }} mb={{ xs: 2, lg: "auto" }}>
                {/* TITLE */}
                <Typography
                  variant={data["title"]["font"]}
                  color={
                    data["title"]["color"] === "dark"
                      ? "black.main"
                      : "gray.main"
                  }
                >
                  {data["title"]["value"]}
                </Typography>
              </Box>
            </Grid>
            {/* RIGHT */}
            <Grid
              item
              xs={12}
              lg={6}
              zeroMinWidth
              alignItems="flex-start"
              justifyContent="center"
              pr={2}
            >
              <Box
                pt={{ xs: 2, lg: 22 }}
                pb={{ xs: 9, lg: 26 }}
                px={{ xs: 2, lg: 10 }}
                mx="auto"
              >
                <Stack rowGap="32px">
                  {/* CONTENT */}
                  <Typography
                    variant={data["content"]["font"]}
                    color={
                      data["content"]["color"] === "dark"
                        ? "black.main"
                        : "gray.main"
                    }
                    textAlign="left"
                  >
                    {data["content"]["value"]}
                  </Typography>
                  {/* BUTTON */}
                  <>
                    {"button" in data && (
                      <Box>
                        <FormButton
                          width="auto"
                          text={data["button"]["text"]["value"]}
                          link={data["button"]["href"]}
                        />
                      </Box>
                    )}
                  </>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InfoCard;
