import useStrapi from "../hooks/useStrapi";
import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { LgBreakpoint } from "../layout";

const Billboard = (props) => {
  const { page, index } = props;
  const { billboards, fetchBillboards, fetchingBillboards } = useStrapi();
  const [data, setData] = useState(null);
  const [numOfStatistics, setNumOfStatistics] = useState(null);

  useEffect(() => {
    if (billboards == null && !fetchingBillboards.current) {
      fetchBillboards();
    } else {
      if (!!billboards) {
        const billboard = billboards.find(
          (_billboard) =>
            _billboard.page.name === page && _billboard.index === index
        );

        setData(billboard != null ? billboard : false);
      }

      if (!!data) {
        if ("statistics" in data && numOfStatistics === null) {
          setNumOfStatistics(data["statistics"].length);
        }
      }
    }
  }, [
    page,
    index,
    billboards,
    fetchBillboards,
    fetchingBillboards,
    data,
    numOfStatistics,
  ]);

  return (
    <>
      {!!data && (
        <Grid
          container
          item
          flexGrow={1}
          sx={{ backgroundColor: "black.main", my: { xs: 10, lg: 30 } }}
        >
          <Grid
            container
            item
            maxWidth={1440}
            justifyContent="center"
            mx="auto"
          >
            {!!LgBreakpoint && (
              <>
                {"tag" in data && (
                  <Grid item>
                    <Box
                      sx={{
                        position: "relative",
                        top: "-10px",
                        height: 57,
                        width: 180,
                        transform: "rotate(-17.51deg)",
                        backgroundColor: "yellow.main",
                        display: LgBreakpoint ? "flex" : "none",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      px={5}
                      py={1}
                    >
                      <Typography
                        variant={data["tag"]["font"]}
                        color={
                          data["tag"]["color"].toLowerCase() === "dark"
                            ? "black.main"
                            : "gray.main"
                        }
                        textAlign="center"
                      >
                        {data["tag"]["value"]}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            <Grid container alignItems="center" py={{ xs: 10, lg: 26 }}>
              {"title" in data && (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box px={{ xs: 2, lg: 2 }}>
                    <Typography
                      variant={data["title"]["font"]}
                      color={
                        data["title"]["color"].toLowerCase() === "light"
                          ? "gray.main"
                          : "black.main"
                      }
                      textAlign="center"
                    >
                      {data["title"]["value"]}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {!!numOfStatistics && numOfStatistics > 0 && (
                <Grid item xs={12}>
                  <Grid container justifyContent="center" pt={10}>
                    {data["statistics"].map((stat) => {
                      const { id, title, content } = stat;
                      const lgColSize = 12 / numOfStatistics;

                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          lg={lgColSize}
                          justifyContent="center"
                          key={`billboard-stat-${id}`}
                        >
                          <Stack direction="column" textAlign="center">
                            <Typography
                              variant={title["font"]}
                              color={
                                title["color"].toLowerCase() === "light"
                                  ? "lavender.main"
                                  : "lavender.dark"
                              }
                            >
                              {title["value"]}
                            </Typography>
                            <Typography
                              variant={content["font"]}
                              color={
                                content["color"].toLowerCase() === "light"
                                  ? "gray.main"
                                  : "black.main"
                              }
                            >
                              {content["value"]}
                            </Typography>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Billboard;
