import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Hero from "../../components/Hero";
import { Img } from "../../components/Image";
import Bottom from "../../components/Bottom";
import Navigation from "../../components/Navigation";
import { ResponseHelmet } from "../../components/MetaHelmet";
import useStrapi from "../../hooks/useStrapi";
import { useEffect, useRef, useState } from "react";

/**
 * @component
 * @module Response
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/thank-you" element={<Response />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Response Page
 *
 * Thank you page for users after submitting their email request for more information.
 */
const Response = () => {
  const { headers, fetchHeaders } = useStrapi();
  const isLoading = useRef(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (headers === null && !isLoading.current) {
      isLoading.current = true;
      fetchHeaders();
    } else {
      isLoading.current = false;

      if (data === null) {
        if (!!headers && "response" in headers && headers["response"] != null) {
          setData(headers["response"]);
        }
      }
    }
  }, [headers, fetchHeaders, data]);

  return (
    <Grid container item xs={12}>
      <ResponseHelmet />
      <Navigation />
      {!!data ? (
        <Hero
          title={
            <Box mt={{ xs: 10, lg: 20 }} ml={{ xs: 2, lg: 10 }}>
              <Typography variant="xlTitle" color="gray.main">
                Thank you for your interest!
              </Typography>
            </Box>
          }
          subTitle={
            <Box ml={{ xs: 2, lg: 10 }}>
              <Typography variant="h4" color="black.light">
                We’ll get back to you soon. In the meantime you can learn more
                about what BoxC can do for you.
              </Typography>
            </Box>
          }
          image={
            <Img
              maxHeight="606px"
              maxWidth="924px"
              mx="auto"
              src={!!data ? data["image"]["data"]["attributes"]["hash"] : ""}
              alt="response-hero-img"
            />
          }
          doTransitions={false}
        />
      ) : (
        <Box
          sx={{
            height: "700px",
            width: "100vw",
            backgroundColor: "black.main",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <Bottom showNav />
    </Grid>
  );
};

export default Response;
