import { Box, Stack } from "@mui/material";

/**
 *
 * @component
 * @module Bulletin
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} props.title Bulletin title.
 * @param {String} props.text Text to be displayed in the Bulletin body.
 * @param {Object} props.button Button object rendered at the bottom of the Bulletin.
 * @description
 * Component for highlighting other relevant site information.
 * @example <caption>Usage:</caption>
 * return (
 *   <Bulletin
 *     title={
 *       <Typography> *your title* </Typography>
 *     }
 *     text={
 *       <Typography> *your body text* </Typography>
 *      }
 *      button={
 *        <Box>
 *          <RequestInfoButton />
 *        </Box>
 *      }
 *    />
 * )
 */
const Bulletin = (props) => {
  const { title, text, button } = props;

  return (
    <Box>
      <Stack rowGap={2}>
        {title && <Box>{title}</Box>}
        {text && <Box>{text}</Box>}
        {button && <Box>{button}</Box>}
      </Stack>
    </Box>
  );
};

export default Bulletin;
