import { useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Img } from "./Image";

/**
 *
 * @component
 * @module ListCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props {page, index}
 * @param {string} props.page the page containing this column card (example: "home")
 * @param {number} props.index the index of the column card on your page (0 indexed)
 * @description
 * Strapi list-card component
 * @example <caption>Usage:</caption>
 * return(
 *   <ListCard page="example-page" index={0} />
 * )
 */
const ListCard = (props) => {
  const { page, index } = props;
  const { listCards, fetchListCards, fetchingListCards } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (listCards == null && !fetchingListCards.current) {
      fetchListCards();
    } else {
      if (!!listCards) {
        const card = listCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : false);
      }
    }
  }, [page, index, listCards, fetchListCards, fetchingListCards, data]);

  return (
    <>
      {!!data ? (
        <>
          {"image" in data && data["image"] != null ? (
            <Grid container item flexGrow={1} justifyContent="center">
              <Grid container maxWidth={1440} mx="auto" px={{ xs: 2, lg: 10 }}>
                <Grid container item xs={12} lg={6} rowGap={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant={
                        data["statement"]["title"]["font"]
                          ? data["statement"]["title"]["font"]
                          : "h1"
                      }
                      color={
                        data["statement"]["title"]["color"].toLowerCase() ===
                        "dark"
                          ? "black.main"
                          : "gray.main"
                      }
                    >
                      {data["statement"]["title"]["value"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mr={{ xs: "auto", lg: 16 }}>
                    <Typography
                      variant={
                        data["statement"]["content"]["font"]
                          ? data["statement"]["content"]["font"]
                          : "h4"
                      }
                      color={
                        data["statement"]["content"]["color"].toLowerCase() ===
                        "dark"
                          ? "ink.200"
                          : "gray.main"
                      }
                    >
                      {data["statement"]["content"]["value"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box py={2}>
                      <Img
                        src={data["image"]}
                        alt={`${data["statement"]["title"]["value"]} Image`}
                        height="auto"
                        maxWidth={606}
                        mx="auto"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  pl={{ xs: 5, lg: 23 }}
                  pr={5}
                  rowSpacing={{ xs: 5, lg: "inherit" }}
                >
                  {data["list"].map((item) => {
                    const { title, content } = item;

                    return (
                      <Grid
                        item
                        xs={12}
                        key={`list-card-${data["id"]}-item-${content["id"]}`}
                      >
                        <Stack direction="column" spacing={1}>
                          <Typography
                            variant={!!title["font"] ? title["font"] : "h3"}
                            color={
                              title["color"].toLowerCase() === "dark"
                                ? "black.main"
                                : "gray.main"
                            }
                          >
                            {title["value"]}
                          </Typography>
                          <Typography
                            variant={
                              !!content["font"] ? content["font"] : "body2"
                            }
                            color={
                              content["color"].toLowerCase() === "dark"
                                ? "ink.200"
                                : "gray.main"
                            }
                          >
                            {content["value"]}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container item flexGrow={1}>
              <Grid container maxWidth={1440} mx="auto">
                <Grid container>
                  {!!data && (
                    <>
                      <Grid item xs={12} lg={6}>
                        <Stack
                          direction="column"
                          rowGap={2}
                          mx={{ xs: 2, lg: 10 }}
                          my={{ xs: 5, lg: 10 }}
                        >
                          <Typography
                            variant={data["statement"]["title"]["font"]}
                            color={
                              data["statement"]["title"][
                                "color"
                              ].toLowerCase() === "dark"
                                ? "black.main"
                                : "gray.main"
                            }
                          >
                            {data["statement"]["title"]["value"]}
                          </Typography>
                          <Typography
                            variant={data["statement"]["content"]["font"]}
                            sx={{ mr: 5 }}
                          >
                            {data["statement"]["content"]["value"]}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack
                          direction="column"
                          rowGap={{ xs: 5, lg: 10 }}
                          ml={{ xs: 2, lg: 10 }}
                          mr={{ xs: 2, lg: 15 }}
                          my={{ xs: 5, lg: 10 }}
                        >
                          {data["list"].map((item) => {
                            const { title, content } = item;

                            return (
                              <Box
                                key={`list-card-${data["id"]}-item-${content["id"]}`}
                              >
                                <Stack direction="column" rowGap={1}>
                                  <Typography
                                    variant={title["font"]}
                                    color={
                                      title["color"].toLowerCase() === "dark"
                                        ? "black.main"
                                        : "gray.main"
                                    }
                                  >
                                    {title["value"]}
                                  </Typography>
                                  <Typography
                                    variant={content["font"]}
                                    color={
                                      content["color"].toLowerCase() === "dark"
                                        ? "black.dark"
                                        : "gray[400]"
                                    }
                                  >
                                    {content["value"]}
                                  </Typography>
                                </Stack>
                              </Box>
                            );
                          })}
                        </Stack>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid container item flexGrow={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListCard;
