const breakpoints = {
  values: {
    xs: 0,
    sm: 375,
    md: false,
    lg: 1440,
    xl: false,
  },
};

export default breakpoints;
