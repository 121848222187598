export const quantities = [
  "< 2,500",
  "2,500 – 4,999",
  "5,000 – 9,999",
  "10,000 – 14,999",
  "15,000 – 19,999",
  "20,000 – 49,999",
  "50,000 – 74,999",
  "75,000 – 99,999",
  "100,000 – 249,000",
  "250,000 +",
];
