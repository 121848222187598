import { useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Desktop, Mobile } from "../layout";
import { Img } from "./Image";
import { ChevronDownIcon, ChevronUpIcon, getDynamicIcon } from "./Icon";
import { styled } from "@mui/material/styles";

const AccordionMenu = ({ text, menuItems }) => {
  const [open, setOpen] = useState(false);
  const handleChange = () => {
    setOpen(!open);
  };

  return (
    <>
      {!!menuItems && menuItems.length > 0 ? (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={Boolean(open)}
          onClick={handleChange}
          disableGutters
          sx={{
            backgroundColor: "gray.main",
            borderColor: "gray.main",
          }}
        >
          <MuiAccordionSummary
            expandIcon={
              <ChevronDownIcon
                sx={{
                  color: "green.main",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: open ? "gray.main" : "gray.main",
            }}
          >
            <Typography
              variant="body1"
              color={open ? "green.main" : "black.main"}
            >
              {text}
            </Typography>
          </MuiAccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: open ? "ink.200" : "gray.main" }}
          >
            <Box py={2} px={1}>
              {menuItems.map((item) => {
                return (
                  <Box key={`${text}-mobile-menu-item-${item["id"]}`}>
                    <Typography
                      variant="body1"
                      color={open ? "gray.main" : "inherit"}
                    >
                      {item["value"]}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Button
          variant="text"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              color: "green.main",
            },
          }}
        >
          <Typography variant="body1">{text}</Typography>
        </Button>
      )}
    </>
  );
};
const PositionedMenu = ({ text, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const buttonId = text + "-button";
  const menuId = text + "-menu";
  const buttonControls = open ? menuId : undefined;
  const menuExpanded = open ? "true" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack width={200} direction="column">
      <Button
        key={buttonId}
        id={buttonId}
        aria-controls={buttonControls}
        aria-haspopup="true"
        aria-expanded={menuExpanded}
        onClick={handleClick}
        endIcon={
          open ? (
            <ChevronUpIcon sx={{ color: "green.main" }} />
          ) : (
            <ChevronDownIcon sx={{ color: "green.main" }} />
          )
        }
      >
        <Stack direction="row">
          <Typography variant="h4" color={open ? "green.main" : "black.main"}>
            {text}
          </Typography>
        </Stack>
      </Button>
      {!!menuItems && menuItems.length > 0 && (
        <Menu
          key={menuId}
          id={menuId}
          aria-labelledby={buttonId}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "ink.200",
              color: "gray.main",
              maxHeight: "20em",
              width: "200px",
              overflow: "auto",
            },
          }}
        >
          {menuItems.map((item) => {
            return (
              <MenuItem
                key={`${text}-menu-item-${item["id"]}`}
                onClick={handleClose}
                sx={{
                  "&:hover": {
                    color: "green.main",
                  },
                }}
              >
                {item["value"]}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Stack>
  );
};
const StyledPositionedMenu = styled(PositionedMenu)(({ theme }) => ({
  hover: {
    color: theme.palette.green.main,
  },
}));

/**
 * @component
 * @module NavigationCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props property object passed to component
 * @param {String} props.name unique identifier for retrieving component from fetched Strapi collection
 * @description
 * Navigation Card Component
 * @example <caption>Usage:</caption>
 * return(
 *   <NavigationCard name="your-navigation-card-name" />
 * )
 * @return {JSX.Element}
 */
const NavigationCard = (props) => {
  const { name } = props;
  const { navigationCards, fetchNavigationCards, fetchingNavigationCards } =
    useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (navigationCards === null && !fetchingNavigationCards.current) {
      fetchNavigationCards();
    } else {
      if (!!navigationCards && data === null) {
        const card = navigationCards.find((card) => card.name === name);

        setData(card != null ? card : null);
      }
    }
  }, [
    navigationCards,
    fetchNavigationCards,
    fetchingNavigationCards,
    data,
    name,
  ]);

  return (
    <>
      {!!data && (
        <Grid container item flexGrow={1} justifyContent="center">
          <Grid container item maxWidth={1440}>
            {/* TITLE */}
            <Grid item xs={12} lg={7}>
              <Box pr={{ xs: 1, lg: "inherit" }} px={{ xs: 2, lg: 15 }}>
                <Typography
                  variant={data["title"]["font"]}
                  color={
                    data["title"]["color"].toLowerCase() === "dark"
                      ? "black.main"
                      : "gray.main"
                  }
                >
                  {data["title"]["value"]}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Stack
                flexGrow={1}
                direction="column"
                rowGap={3}
                pl={2}
                pr={{ xs: 2, lg: 15 }}
              >
                {/* CONTENT */}
                <Box>
                  <Typography
                    variant={data["content"]["font"]}
                    color={
                      data["content"]["color"].toLowerCase() === "dark"
                        ? "black.main"
                        : "gray.main"
                    }
                  >
                    {data["content"]["value"]}
                  </Typography>
                </Box>
                {/* LEGEND */}
                <Box>
                  <Stack
                    direction={{ xs: "column", lg: "row" }}
                    justifyContent="flex-start"
                  >
                    {data["legend"].map((item, index) => {
                      const { icon, id, text } = item;

                      return (
                        <Box key={`legend-item-${id}-${index}`} flex={1}>
                          <Stack direction="row" columnGap={1}>
                            <Box>{getDynamicIcon({ name: icon })}</Box>
                            <Typography variant={text["font"]}>
                              {text["value"]}
                            </Typography>
                          </Stack>
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="column"
                flexGrow={1}
                px={{ xs: "inherit", lg: 10 }}
                pt={{ xs: 5, lg: 10 }}
              >
                {/* MAP */}
                <Box>
                  <Img src={data["image"]} alt="global-map-img" />
                </Box>
                <Grid
                  container
                  px={{ xs: 0, lg: 15 }}
                  py={{ xs: 5, lg: 10 }}
                  spacing={0}
                >
                  {data["navigation"].map((nav) => {
                    const { item, children } = nav;

                    return (
                      <Grid item key={`map-card-menu-${item["id"]}`} xs={12} lg>
                        <Desktop>
                          <StyledPositionedMenu
                            text={item["value"]}
                            menuItems={children}
                          />
                        </Desktop>
                        <Mobile>
                          <AccordionMenu
                            text={item["value"]}
                            menuItems={children}
                          />
                        </Mobile>
                      </Grid>
                    );
                  })}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NavigationCard;
