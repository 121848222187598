import { useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { getDynamicIcon } from "./Icon";

const GridCard = (props) => {
  const { page, index } = props;
  const { gridCards, fetchGridCards, fetchingGridCards } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (gridCards == null && !fetchingGridCards.current) {
      fetchGridCards();
    } else {
      if (!!gridCards) {
        const card = gridCards.find(
          (card) => card.page.name === page && card.index === index
        );

        setData(card != null ? card : null);
      }
    }
  }, [page, index, gridCards, fetchGridCards, fetchingGridCards, data]);

  return (
    <>
      {!!data && (
        <Grid container item flexGrow={1} justifyContent="center">
          <Grid
            container
            item
            sx={{ maxWidth: "1440px", py: { xs: 10, lg: 20 } }}
          >
            <Grid container mx={{ xs: "auto", lg: 0 }}>
              <Grid item xs={12} sx={{ px: { xs: 2, lg: 10 } }}>
                <Typography variant={data["title"]["font"]}>
                  {data["title"]["value"]}
                </Typography>
              </Grid>
              <Grid item pt={{ xs: 5, lg: 10 }} px={{ xs: 2, lg: 15 }}>
                <Grid
                  container
                  px={{ xs: 0, lg: 5 }}
                  columnSpacing={35}
                  rowSpacing={{ xs: 5, lg: 10 }}
                >
                  {data["items"].map((item, index) => {
                    const { icon, title, content } = item;

                    return (
                      <Grid key={`grid-card-item-${index}`} item xs={12} lg={6}>
                        <Stack
                          flex={1}
                          direction="row"
                          spacing={{ xs: 1, lg: 2 }}
                        >
                          <Box>{getDynamicIcon({ name: icon })}</Box>
                          <Box>
                            <Stack direction="column" spacing={1}>
                              <Box>
                                <Typography
                                  variant={title["font"]}
                                  color={
                                    title["color"].toLowerCase() === "dark"
                                      ? "black.main"
                                      : "gray.main"
                                  }
                                >
                                  {title["value"]}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant={content["font"]}
                                  color={
                                    content["color"].toLowerCase() === "dark"
                                      ? "ink.200"
                                      : "gray.main"
                                  }
                                >
                                  {content["value"]}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GridCard;
