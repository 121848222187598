import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { BlogPostHelmet } from "../../../components/MetaHelmet";
import Navigation from "../../../components/Navigation";
import { BlogPostImage } from "../../../components/Image";
import { BlogPageHero } from "./BlogPageHero";
import { ChevronLeft } from "@mui/icons-material";
import Bottom from "../../../components/Bottom";
import { RoundButton } from "../../../components/Button";
import MuiMarkdown from "mui-markdown";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getDateMonthString } from "../../../utils/dates";
import Error from "../../error";

// component for handling navigating back to previous page in browser history
const BackButton = (props) => {
  return (
    <RoundButton
      width="auto"
      text="Back"
      startIcon={<ChevronLeft />}
      {...props}
    />
  );
};

// component for handling markdown font properties
const BodyText = ({ children, ...props }) => (
  <Typography
    variant="body"
    sx={{
      fontFamily: "BoxC Diatype",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
    }}
    {...props}
  >
    {children}
  </Typography>
);

/**
 * @component
 * @module BlogPage
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/blog/:blogSlug" element={<BlogPage />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Individual Blog Page
 */
const BlogPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [blogPost, setBlogPost] = useState(null);
  const id = useParams()["id"];
  const titleFromURL = useParams()["title"];
  const [error, setError] = useState(null);
  const handleNavBackToBlog = () => {
    navigate("/blog");
  };
  const checkTitleFromURL = useCallback(
    (title) => {
      const formattedTitleForCheck = title.replace(/\s/g, "");

      if (titleFromURL !== formattedTitleForCheck) {
        navigate("/blog");
      }
    },
    [navigate, titleFromURL]
  );

  // checks for location state to prevent calling server more than necessary
  useEffect(() => {
    if (blogPost === null) {
      //blog post data passed from thumbnail state
      if (location["state"]) {
        const { state } = location;
        const { title, author, createdAt, updatedAt, story, image } = state;

        setBlogPost({
          title,
          author,
          createdAt,
          updatedAt,
          story,
          image,
        });
      }
      //retrieving blog data from Strapi resource
      else {
        axios
          .get(`/api/blog/posts/${id}`)
          .then((response) => {
            const { success } = response.data;

            if (success) {
              const { post } = response.data;
              const { content, publishedAt, updatedAt, title, author, photo } =
                post["attributes"];

              checkTitleFromURL(title);

              // author
              const { firstname, lastname } = author
                ? author.data.attributes
                : { firstname: "", lastname: "" };
              const theAuthor =
                firstname === "" ? null : firstname + " " + lastname;

              // blog image
              // const devPhotoSource = photo["data"]?"http://localhost:1337" + photo.data.attributes.formats.large.url:null;
              const photoSource =
                photo["data"] !== null
                  ? "https://cms.boxc.com/" +
                    photo.data.attributes.formats.large.url
                  : null;

              //create date & update date
              const publishedDate = new Date(publishedAt);
              const updatedDate = new Date(updatedAt);
              const publishedFormatted =
                getDateMonthString(publishedDate) +
                " " +
                publishedDate.getDate() +
                ", " +
                publishedDate.getFullYear();
              const updatedFormatted =
                getDateMonthString(updatedDate) +
                " " +
                updatedDate.getDate() +
                ", " +
                updatedDate.getFullYear();

              setBlogPost({
                title,
                author: theAuthor,
                createdAt: publishedFormatted,
                updatedAt: updatedFormatted,
                story: content,
                // image: devPhotoSource,
                image: photoSource,
              });
            } else {
              setBlogPost(null);
              setError(response.data.message);
            }
          })
          .catch((error) => {
            setBlogPost(null);
            setError(error.message);
          });
      }
    }
  }, [blogPost, checkTitleFromURL, location, id]);

  return (
    <Grid container item xs={12}>
      <Navigation />
      {!!error && <Error message={error} />}
      {!!blogPost && (
        <>
          <BlogPostHelmet title={blogPost["title"]} />
          <BlogPageHero title={blogPost["title"]} />
          <Grid container item sx={{ maxWidth: 1440, mx: "auto" }}>
            <Card
              sx={{
                backgroundColor: "background.default",
                width: "100%",
                height: "100%",
                mx: { xs: 2, lg: 10 },
                mt: { xs: "inherit", lg: 10 },
              }}
            >
              <CardHeader
                title={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      mb: 10,
                    }}
                  >
                    <Typography variant="h6">
                      Created: {blogPost["createdAt"]}
                    </Typography>
                  </Box>
                }
              />
              <CardContent>
                <Stack direction="column" spacing={5}>
                  {!!blogPost["image"] && (
                    <Box>
                      <BlogPostImage
                        src={setBlogPost["image"]}
                        alt="Blog Image"
                      />
                    </Box>
                  )}
                  <Box>
                    <MuiMarkdown
                      options={{
                        overrides: {
                          p: {
                            component: BodyText,
                          },
                        },
                      }}
                    >
                      {blogPost["story"]}
                    </MuiMarkdown>
                  </Box>
                </Stack>
              </CardContent>
              <CardActions sx={{ mt: 10 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <BackButton
                      onClick={handleNavBackToBlog}
                      sx={{ mx: "auto" }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        width: "100%",
                        display: { xs: "none", lg: "flex" },
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Stack direction="column" justifyContent="center">
                        <Typography variant="h6">
                          Updated: {blogPost["updatedAt"]}
                        </Typography>
                        <Typography variant="h5">
                          {blogPost["author"] !== null &&
                            `Author: ${blogPost["author"]}`}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </>
      )}
      <Bottom showPreFooter showNav />
    </Grid>
  );
};

export default BlogPage;
