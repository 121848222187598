import { Grid, Stack, Typography } from "@mui/material";

const FAQ = () => {
  return (
    <Grid container item maxWidth={1440} mx="auto">
      <Stack direction="column" flexGrow={1} mx={{ xs: 2, lg: 20 }} mt={10}>
        <Stack direction="column">
          <Typography variant="h3" color="black.main" py={1}>
            When will my tracking information appear?
          </Typography>
          <Typography variant="body2" color="black.main" pb={5} paragraph>
            Tracking events are typically available within 24-48 hours after you
            have received your order confirmation from the e-commerce retailer
            or marketplace. If no tracking events are available after 48 hours
            of receiving your order confirmation, please contact the e-commerce
            retailer or marketplace.
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography variant="h3" color="black.main" py={1}>
            When should I expect delivery?
          </Typography>
          <Typography variant="body2" color="black.main" pb={5} paragraph>
            Delivery times are based on the shipping service selected by the
            e-commerce retailer or marketplace. For items shipped within the
            United States; your package will be delivered by your United States
            Postal Service (USPS) carrier at the time they typically deliver
            your mail or other delivery service provider. For international
            packages, they will be delivered by the local delivery service
            provider for the destination country. The delivery service provider
            can be either the local post office or another delivery company.
            Please check your mailbox/post box for your package or any special
            notices requiring action on your part.
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography variant="h3" color="black.main" py={1}>
            Who do I contact if I haven't received my package?
          </Typography>
          <Typography variant="body2" color="black.main" pb={5} paragraph>
            If your package exceeds the expected delivery time, your shipment
            may have experienced a delay. Please note, delivery delays can be
            caused by weather events, holidays, customs issues, labor strikes or
            other uncontrollable events . If your package has not arrived after
            the expected delivery time, please contact the e-commerce retailer
            or marketplace.
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default FAQ;
