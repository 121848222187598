import { Box, Typography } from "@mui/material";
import TimelineEvent from "./TimelineEvent";

const TimelineDate = ({ trackerDate, trackerEvents }) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
        ml={{ xs: "auto", lg: 15 }}
        width={{ xs: "auto", lg: 300 }}
        bgcolor="black.light"
      >
        <Typography variant="h3">{trackerDate}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {trackerEvents.map((trackerEvent) => {
          const randomKey = (Math.random() * 100000000000)
            .toString()
            .split(".")[0];

          return <TimelineEvent key={randomKey} eventData={trackerEvent} />;
        })}
      </Box>
    </Box>
  );
};

export default TimelineDate;
