import { useEffect } from "react";
import {
  Box,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FooterLogo } from "./Logo";
import useStrapi from "../hooks/useStrapi";

const NavigationList = (props) => {
  const { details } = props;
  const { name, navigation } = details;

  return (
    <Grid container item xs={12} lg={2}>
      <Stack direction="column" spacing={1} textAlign="left">
        <Typography variant="h5" color="neutral.white">
          {name}
        </Typography>
        {navigation.map((nav) => {
          const { name, href } = nav;

          return (
            <Link
              key={`nav-list-item-${name}`}
              variant="body1"
              underline="none"
              href={href}
              color="black.light"
            >
              {name}
            </Link>
          );
        })}
      </Stack>
    </Grid>
  );
};

/**
 *
 * @component
 * @module Footer
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Boolean} showNav show navigation links - or not
 * @param {Boolean} showPrivacy show privacy link - or not
 * @description
 * Footer component containing site navigation.
 * @example <caption>Usage:</caption>
 * return (
 *   <Footer showNav={true} showPrivacy={false} />
 * )
 */
const Footer = ({ showNav, showPrivacy }) => {
  const { footer, fetchFooter, fetchingFooter } = useStrapi();

  useEffect(() => {
    if (footer === null && !fetchingFooter.current) {
      fetchFooter();
    }
  }, [footer, fetchFooter, fetchingFooter]);

  return (
    <Grid item xs={12} sx={{ bgcolor: "black.main" }}>
      <Box
        display="flex"
        alignItems="center"
        pt={showNav ? { xs: 10, lg: 32 } : { xs: 5, lg: 10 }}
        pb={showNav ? { xs: 10, lg: 20 } : { xs: 5, lg: 10 }}
        maxWidth={1440}
        mx={"auto"}
      >
        <Grid
          container
          item
          alignItems="flex-start"
          justifyContent="center"
          rowGap={{ xs: 8, lg: 1 }}
          px={{ xs: 2, lg: "inherit" }}
        >
          <Grid
            container
            item
            xs={12}
            lg={showNav ? 4 : 9}
            justifyContent={
              useMediaQuery(useTheme().breakpoints.up("lg")) && showNav
                ? "center"
                : "flex-start"
            }
          >
            <Box maxHeight={148} maxWidth={149}>
              <FooterLogo />
            </Box>
          </Grid>
          {showNav && (
            <>
              {!!footer &&
                footer.map((navList) => (
                  <NavigationList
                    details={navList}
                    key={`nav-list-${navList.name}`}
                  />
                ))}
            </>
          )}
        </Grid>
      </Box>
      <Box
        textAlign="left"
        p={{ xs: "inherit", lg: 5 }}
        pb={{ xs: 4 }}
        pr={{ xs: 1 }}
        pl={{ xs: 1 }}
        maxWidth={1440}
        mx="auto"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          pl={{ xs: "inherit", lg: 22 }}
        >
          <Typography variant="monoCaption" textAlign="left" color="gray.400">
            &copy; {new Date().getFullYear()} BoxC Logistics, Inc. All Rights
            Reserved.
          </Typography>
          {showPrivacy && (
            <Link
              href="/privacy"
              underline="none"
              variant="monoCaption"
              textAlign="left"
              color="gray.400"
            >
              Privacy
            </Link>
          )}
        </Stack>
      </Box>
    </Grid>
  );
};

export default Footer;
