import { Box, Stack, Typography } from "@mui/material";
import { StoreIcon } from "./Icon";

/**
 *
 * @component
 * @module ECommerceCard
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props properties object
 * @param {string} props.content
 * @description
 * E-Commerce information card used in the ListMediaCard compontent
 * @example <caption>Usage:</caption>
 * return(
 *   <ECommerceCard content="Your e-commerce content" />
 * )
 */
const ECommerceCard = (props) => {
  const {
    statement: { icon, title, content },
  } = props;

  return (
    <Box bgcolor="ink.300">
      <Stack spacing={2} p={2}>
        {!!icon && (
          <Box>
            <StoreIcon />
          </Box>
        )}
        <Box textTransform="uppercase">
          <Typography
            variant={!!title["font"] ? title["font"] : "h6"}
            color={
              title["color"].toLowerCase() === "light"
                ? "gray.main"
                : "black.main"
            }
          >
            {title["value"]}
          </Typography>
        </Box>
        <Box pr={{ xs: 2, lg: 5 }}>
          <Typography
            variant={!!content["font"] ? content["font"] : "body1"}
            color={
              content["color"].toLowerCase() === "light"
                ? "gray.main"
                : "black.main"
            }
          >
            {content["value"]}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default ECommerceCard;
