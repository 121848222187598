import { Grid } from "@mui/material";
import PreFooter from "./PreFooter";
import Footer from "./Footer";

/**
 *
 * @component
 * @module Bottom
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Boolean} showPreFooter initial visible state of pre-footer component (defaults to false).
 * @param {Boolean} showNav initial visible state of navigation (defaults to false).
 * @param {Boolean} showPrivacy initial visible state of "Privacy" link in footer (defaults to false).
 * @description
 * Container for managing visibility of:
 * - Privacy Link
 * - Pre-Footer component
 * - Navigation
 * @example <caption>Usage:</caption>
 * return(
 *   <Bottom showPreFooter showNav showPrivacy />
 * )
 */
const Bottom = ({ showPreFooter, showNav, showPrivacy }) => {
  return (
    <Grid container item justifyContent="center">
      {showPreFooter && <PreFooter />}
      <Grid container sx={{ backgroundColor: "black.main" }}>
        <Footer showNav={showNav} showPrivacy={showPrivacy} />
      </Grid>
    </Grid>
  );
};

export default Bottom;
