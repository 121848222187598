import { Grid, Stack, Typography } from "@mui/material";
import Form from "./Form";
import Bottom from "../../components/Bottom";
import Navigation from "../../components/Navigation";
import { RequestMoreInfoHelmet } from "../../components/MetaHelmet";

/**
 * @component
 * @module RequestMoreInfo
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/request-more-info" element={<RequestMoreInfo />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Request More Information Page
 *
 * Allows a user to send an email request for more information about BoxC.
 * @TODO
 * add workflow for submitting an email request for more information.
 */
const RequestMoreInfo = () => {
  return (
    <Grid container item xs={12}>
      <RequestMoreInfoHelmet />
      <Navigation />
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Stack
          direction="column"
          rowGap={2}
          p={{ xs: 5, lg: 10 }}
          pb={{ xs: "inherit", lg: 20 }}
        >
          <Typography variant="requestMoreInfo" color="black.main">
            Request More Information
          </Typography>
          <Form />
        </Stack>
      </Grid>
      <Bottom showNav />
    </Grid>
  );
};

export default RequestMoreInfo;
