import { useEffect, useState } from "react";
import useStrapi from "../hooks/useStrapi";
import { Box, Grid, Typography } from "@mui/material";
import Bulletin from "./Bulletin";
import { RoundButton } from "./Button";
import _ from "underscore";

/**
 *
 * @component
 * @module CallToAction
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {Object} props {page, index}
 * @param {string} props.page the page containing this component (example: "home")
 * @param {number} props.index the index of this component on your page (0 indexed)
 * @param {function} [props.handleScroll] optional function that scrolls to a defined reference
 * @description
 * Strapi call-to-action component
 * @example <caption>Usage:</caption>
 * return(
 *   <CallToAction page="example-page" index={0} />
 * )
 */
const CallToAction = (props) => {
  const { page, index } = props;
  const hasScroll = _(props).has("handleScroll");
  const { callToActions, fetchCallToActions, fetchingCallToActions } =
    useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (callToActions == null && !fetchingCallToActions.current) {
      fetchCallToActions();
    } else {
      if (!!callToActions) {
        const callToAction = callToActions.find(
          (_callToAction) =>
            _callToAction.page.name === page && _callToAction.index === index
        );

        setData(callToAction != null ? callToAction : false);
      }
    }
  }, [
    page,
    index,
    callToActions,
    fetchCallToActions,
    fetchingCallToActions,
    data,
  ]);

  return (
    <>
      {!!data && (
        <>
          {data["size"] === "small" && (
            <Grid container item flexGrow={1}>
              <Grid container maxWidth={1440} mx="auto">
                <Box mx={{ xs: "auto", lg: 35 }} py={{ xs: 10, lg: 20 }}>
                  {!!data && (
                    <Bulletin
                      title={
                        <Typography
                          flexGrow={1}
                          textAlign="center"
                          variant={data["title"]["font"]}
                          color={
                            data["title"]["color"].toLowerCase() === "dark"
                              ? "black.main"
                              : "gray.main"
                          }
                        >
                          {data["title"]["value"]}
                        </Typography>
                      }
                      text={
                        !!data["subTitle"] ? (
                          <Box sx={{ px: { xs: "inherit", lg: 35 } }}>
                            <Typography
                              variant={data["subTitle"]["font"]}
                              color={
                                data["subTitle"]["color"].toLowerCase() ===
                                "dark"
                                  ? "black.light"
                                  : "gray.main"
                              }
                            >
                              {data["subTitle"]["value"]}
                            </Typography>
                          </Box>
                        ) : null
                      }
                      button={
                        <Box textAlign="center">
                          {!!hasScroll ? (
                            <RoundButton
                              text={data["button"]["text"]["value"]}
                              width="auto"
                              onClick={props.handleScroll}
                            />
                          ) : (
                            <RoundButton
                              text={data["button"]["text"]["value"]}
                              link={data["button"]["href"]}
                              target="_blank"
                            />
                          )}
                        </Box>
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
          {data["size"] === "large" && (
            <Grid container item flexGrow={1}>
              <Grid container item maxWidth={1440} mx="auto">
                <Grid container item xs={12} textAlign="center" zeroMinWidth>
                  <Box pt={{ xs: 10, lg: 25 }} px={{ xs: 2, sm: 5, lg: 10 }}>
                    <Bulletin
                      title={
                        <Box sx={{ px: { xs: 1, lg: 15 } }}>
                          <Typography
                            variant={data["title"]["font"]}
                            color={
                              data["title"]["color"].toLowerCase() === "dark"
                                ? "black.main"
                                : "gray.main"
                            }
                          >
                            {data["title"]["value"]}
                          </Typography>
                        </Box>
                      }
                      text={
                        !!data["subTitle"] ? (
                          <Box sx={{ px: { xs: "inherit", lg: 35 } }}>
                            <Typography
                              variant={data["subTitle"]["font"]}
                              color={
                                data["subTitle"]["color"].toLowerCase() ===
                                "dark"
                                  ? "black.light"
                                  : "gray.main"
                              }
                            >
                              {data["subTitle"]["value"]}
                            </Typography>
                          </Box>
                        ) : null
                      }
                      button={
                        <Box textAlign="center">
                          {!!hasScroll ? (
                            <RoundButton
                              text={data["button"]["text"]["value"]}
                              width="auto"
                              onClick={props.handleScroll}
                            />
                          ) : (
                            <RoundButton
                              text={data["button"]["text"]["value"]}
                              link={data["button"]["href"]}
                              target="_blank"
                            />
                          )}
                        </Box>
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default CallToAction;
