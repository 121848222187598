import { Box, Grid, Typography } from "@mui/material";

export const BlogPageHero = ({ title }) => {
  return (
    <Grid item xs={12} bgcolor="black.main" pl={{ xs: 2, lg: 25 }} py={5}>
      <Box maxWidth={1440} mx="auto">
        <Typography variant="xlTitle" color="gray.main">
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};
