import { BackboardLogo } from "../../components/Logo";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";

/**
 * @component
 * @module Backboard
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route path="/*" element={<Backboard />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Backboard Page
 *
 * Landing page for user's instead of 404 error.
 */
const Backboard = (props) => {
  return (
    <Grid container>
      <Helmet prioritzeSeo>
        <title>BoxC</title>
      </Helmet>
      <Grid item xs={12}>
        <BackboardLogo {...props} />
      </Grid>
    </Grid>
  );
};
export default Backboard;
