import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Desktop } from "../layout";
import { ArrowRightIcon, CloseFillIcon } from "./Icon";
import { useSessionStorage } from "../hooks/storage";

/**
 *
 * @component
 * @module AlertBar
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} text text to be displayed on banner.
 * @param {Boolean} showBanner initial visible state (defaults to false).
 * @description
 * Announcement bar rendered at the top of the page.
 * Intended for highlighting promotions and announcements to users.
 * @example <caption>Usage:</caption>
 * return(
 *   <AlertBar
 *     text="Your Announcement"
 *     showBanner={false}
 *  />
 * )
 */
const AlertBar = ({ text, showBanner }) => {
  const [bannerVisible, setBannerVisible] = useSessionStorage(
    "showAnnouncement",
    false
  );
  const handleBanner = () => {
    setBannerVisible(!bannerVisible);
  };

  return (
    <Grid container item justifyContent="center">
      <Grid item xs={12} zeroMinWidth>
        <Desktop>
          <Collapse in={showBanner && bannerVisible}>
            <Paper
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                backgroundColor: "green.main",
                height: "50px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" color="neutral.white">
                  {text}
                </Typography>
                <ArrowRightIcon sx={{ color: "neutral.white", pl: 1 }} />
              </Box>
              <IconButton onClick={handleBanner} sx={{ flexShrink: 1 }}>
                <CloseFillIcon sx={{ color: "neutral.white" }} />
              </IconButton>
            </Paper>
          </Collapse>
        </Desktop>
      </Grid>
    </Grid>
  );
};

export default AlertBar;
