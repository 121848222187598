import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "underscore";
import EventNodeImg from "../vendor/static/illustration/event-node.png";

// STYLED
export const StyledImage = styled(Box)`
  height: ${(props) => (props.height ? props.height : "auto")};
  width: 100%;
`;

// WRAPPED IMAGE
export const Img = (props) => (
  <Box {..._(props).omit(["src", "alt", "ext"])}>
    <StyledImage
      component="img"
      src={
        process.env.NODE_ENV === "development"
          ? `//cms.boxc.com${props.src}`
          : props.src
      }
      alt={props.alt}
    />
  </Box>
);

// TRACKING PAGE
export const EventNodeImage = (props) => (
  <Box {...props}>
    <StyledImage component="img" src={EventNodeImg} alt="event-node" />
  </Box>
);

// BLOG POST
export const BlogPostImage = (props) => (
  <Box {...props}>
    <StyledImage component="img" src={props.src} alt={props.alt} />
  </Box>
);
