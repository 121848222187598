/**
 * @component
 * @category Client
 * @subcategory
 * @module App
 * @name client
 * @description
 * React application bootstrapped with create-react-app, utilizing react-router-dom <BrowserRouter/> for navigation.
 * @example <caption>client/App.js</caption>
 * {
 *   const App = () => {
 *     return(
 *       <BrowserRouter>
 *         <Routes>
 *           <Route index element={<Home/>} />
 *           <Route exact path="/logistics" element={<Logistics />} />
 *           <Route exact path="/e-commerce" element={<Ecommerce />} />
 *           <Route exact path="/how-it-works" element={<HowItWorks />} />
 *           <Route exact path="/about" element={<About />} />
 *           <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
 *           <Route exact path="/terms" element={<TermsAndConditions />} />
 *           <Route exact path="/privacy" element={<PrivacyPolicy />} />
 *           <Route exact path="/request-more-info" element={<RequestMoreInfo />} />
 *           <Route exact path="/thank-you" element={<Response />} />
 *           <Route exact path="/track" element={<Track />} />
 *           <Route path="/track/:trackingNumber" element={<Track />} />
 *           <Route path="*" element={<Backboard/>} />
 *         </Routes>
 *       </BrowserRouter>
 *     )
 *   }
 * }
 * export default App;
 * @example <caption>client/index.js</caption>
 * {
 *   const rootDiv = window.document.getElementById("root");
 *   const root = ReactDOM.createRoot(rootDiv);
 *
 *   root.render(
 *     <StrictMode>
 *         <App />
 *     </StrictMode>
 *   );
 * }
 */
import { HelmetProvider } from "react-helmet-async";
import { Box, Grid, ThemeProvider } from "@mui/material";
import BOXC_THEME from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Backboard from "./pages/backboard/index";
import Home from "./pages/home/index";
import Logistics from "./pages/logistics/index";
import HowItWorks from "./pages/how-it-works";
import About from "./pages/about";
import TermsAndConditions from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import RequestMoreInfo from "./pages/request-more-info";
import Response from "./pages/response";
import Ecommerce from "./pages/ecommerce";
import Track from "./pages/track";
import AlertBar from "./components/AlertBar";
import Blog from "./pages/blog";
import BlogPage from "./pages/blog/page";
import { StrapiProvider } from "./contexts/StrapiContext";

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter basename="/">
        <StrapiProvider>
          <ThemeProvider theme={BOXC_THEME}>
            <Box
              className="root"
              sx={{
                backgroundColor: "background.default",
                minWidth: "375px",
              }}
            >
              <Grid container>
                <AlertBar
                  text="BoxC banner to highlight promotions and announcements."
                  showBanner={false}
                />
                <Routes>
                  <Route index element={<Home />} />
                  <Route exact path="/logistics" element={<Logistics />} />
                  <Route exact path="/e-commerce" element={<Ecommerce />} />
                  <Route exact path="/how-it-works" element={<HowItWorks />} />
                  <Route exact path="/about" element={<About />} />
                  <Route
                    exact
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route exact path="/terms" element={<TermsAndConditions />} />
                  <Route exact path="/privacy" element={<PrivacyPolicy />} />
                  <Route
                    exact
                    path="/request-more-info"
                    element={<RequestMoreInfo />}
                  />
                  <Route exact path="/thank-you" element={<Response />} />
                  <Route exact path="/track" element={<Track />} />
                  <Route path="/track/:trackingNumber" element={<Track />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route exact path="/blog/:id/:title" element={<BlogPage />} />
                  <Route exact path="/r/:user_id" element={<Home />} />
                  <Route path="*" element={<Backboard />} />
                </Routes>
              </Grid>
            </Box>
          </ThemeProvider>
        </StrapiProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
