import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { BlogPostImage } from "../../../components/Image";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { RoundButton } from "../../../components/Button";
import MuiMarkdown from "mui-markdown";

const BlogThumbnailNavButton = (props) => {
  const { id, title, author, createdAt, updatedAt, story, image } = props;

  return (
    <RoundButton
      variant="contained"
      component={Link}
      text="Continue Reading"
      width="auto"
      endIcon={<ChevronRight />}
      to={{
        pathname: `/blog/${id}/${title.replace(/\s/g, "")}`,
      }}
      state={{
        id,
        title,
        author,
        createdAt,
        updatedAt,
        story,
        image,
      }}
      sx={{ bgcolor: "green", color: "gray.light" }}
    />
  );
};

// component for handling markdown font properties
const BodyText = ({ children, ...props }) => (
  <Typography
    variant="body"
    sx={{
      fontFamily: "BoxC Diatype",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
    }}
    {...props}
  >
    {children}
  </Typography>
);

/**
 * @component
 * @module BlogThumbnail
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @param {String} title The text to display as the Blog Post's title.
 * @param {String} author Author of the Blog Post.
 * @param {Date} createDate Date of blog post creation.
 * @param {String} post Thumbnail summary of Blog story.
 * @param {String} story Text to be displayed as the Blog Post's main content.
 * @param {String} image Blog Thumbnail image source.
 * @description
 * Individual Blog story represented as a thumbnail for populating Blog landing page
 * @example <caption>Usage:</caption>
 * return (
 *   <BlogThumbnail
 *      title="<your_title>"
 *      author="<your_author>"
 *      createDate="<your_createDate>"
 *      post="<your_post>"
 *      story="<your_story>"
 *      image="<your_image>"
 *      content="<your_content>"
 *   />
 * )
 *
 */
const BlogThumbnail = ({
  id,
  title,
  author,
  createdAt,
  updatedAt,
  post,
  story,
  image,
}) => {
  return (
    <Card
      sx={{
        maxWidth: "1280px",
        flexGrow: 1,
        my: { xs: 2, lg: 5 },
        mx: { xs: 2, lg: "auto" },
      }}
    >
      <CardHeader
        title={
          <Stack
            direction="row"
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">{title}</Typography>
            <Typography variant="h6" color="black.main">
              {createdAt}
            </Typography>
          </Stack>
        }
        sx={{ bgcolor: "green.light" }}
      />
      <CardContent>
        {!!image && <BlogPostImage src={image} alt="Blog Image" />}
        <Box sx={{ my: "10px" }}>
          <MuiMarkdown
            options={{
              overrides: {
                p: {
                  component: BodyText,
                },
              },
            }}
          >
            {post}
          </MuiMarkdown>
        </Box>
      </CardContent>
      <CardActions
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <BlogThumbnailNavButton
          id={id}
          title={title}
          createdAt={createdAt}
          updatedAt={updatedAt}
          author={author}
          story={story}
          image={image}
        />
      </CardActions>
    </Card>
  );
};

export default BlogThumbnail;
