export const categories = [
  "Animals & Pet Supplies",
  "Apparel & Accessories",
  "Arts & Entertainment",
  "Baby & Toddler",
  "Business & Industrial",
  "Cameras & Optics",
  "Electronics",
  "Food, Beverages & Tobacco",
  "Furniture",
  "Hardware",
  "Health & Beauty",
  "Home & Garden",
  "Luggage & Bags",
  "Mature",
  "Media",
  "Office Supplies",
  "Religious & Ceremonial",
  "Software",
  "Sporting Goods",
  "Toys & Games",
  "Vehicles & Parts",
];
