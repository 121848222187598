import { Box, Stack, Typography } from "@mui/material";
import {
  CheckCircleIcon,
  CircleEnRouteIcon,
  CircleExceptionIcon,
} from "../../components/Icon";

const Status = ({ status }) => {
  const { color, date, city, province, country, postalCode } = status;
  status.parsedText =
    color === "#2E8049"
      ? "Delivered"
      : color === "#FF0000"
      ? "Exception"
      : "En Route";

  return (
    <Box mx={{ xs: 2, lg: 40 }} mb={10}>
      <Stack direction="column">
        <Stack direction="row" spacing={2} alignItems="center">
          {color === "#2E8049" && (
            <CheckCircleIcon sx={{ height: 60, width: 60 }} />
          )}
          {color === "#E5A830" && (
            <CircleEnRouteIcon sx={{ height: 60, width: 60 }} />
          )}
          {color === "#FF0000" && (
            <CircleExceptionIcon sx={{ height: 60, width: 60 }} />
          )}
          <Typography variant="h2" color={color}>
            {status.parsedText}
          </Typography>
        </Stack>
        <Typography variant="h5">{date}</Typography>
        <Stack direction="column">
          <Typography color={color} variant="h4">
            {`${city}`}
          </Typography>
          <Typography color={color} variant="h4">
            {`${province} ${postalCode}`}
          </Typography>
          <Typography color={color} variant="h4">
            {`${country}`}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Status;
