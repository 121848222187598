import { styled } from "@mui/material/styles";
import { Box, BoxProps, useMediaQuery } from "@mui/material";

const StyledMobile = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "inherit",
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));
const StyledDesktop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "none",
  },
  [theme.breakpoints.up("lg")]: {
    display: "inherit",
  },
}));
export const Mobile = (props: BoxProps) => <StyledMobile {...props} />;
export const Desktop = (props: BoxProps) => <StyledDesktop {...props} />;
export const Content = (props) => {
  const { children, color, screen } = props;

  return screen ? (
    screen === "desktop" ? (
      <Desktop sx={{ backgroundColor: color, flexGrow: 1 }}>
        <Box sx={{ maxWidth: "1440px", mx: "auto" }} children={children} />
      </Desktop>
    ) : (
      <Mobile sx={{ backgroundColor: color, flexGrow: 1 }}>
        <Box sx={{ maxWidth: "1440px", mx: "auto" }} children={children} />
      </Mobile>
    )
  ) : (
    <Box sx={{ backgroundColor: color, flexGrow: 1 }}>
      <Box sx={{ maxWidth: "1440px", mx: "auto" }} children={children} />
    </Box>
  );
};
export const LgBreakpoint = (theme) =>
  useMediaQuery(theme.breakpoints.up("lg"));
