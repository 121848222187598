import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import _ from "underscore";

export const ArrowRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" />
    </SvgIcon>
  );
};
export const ChampionCreativityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="34"
      height="48"
      viewBox="0 0 34 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_954_7914)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.896 33.5968H9.1954L2.64648 19.0553L3.59804 12.1304C3.59804 9.30006 4.73536 6.58561 6.7598 4.58423C8.78424 2.58286 11.53 1.4585 14.393 1.4585H18.563C22.0729 1.45849 25.4391 2.83608 27.9221 5.28854C30.4051 7.741 31.8016 11.0677 31.8048 14.5375L30.4494 22.5771L24.896 33.5968Z"
          fill="#B8E5C7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9999 7.44431e-06C14.7682 -0.00207004 12.558 0.430697 10.4955 1.27357C8.43309 2.11644 6.5589 3.35289 4.98009 4.91224C3.40128 6.47159 2.14882 8.32327 1.29429 10.3614C0.439768 12.3996 -6.62338e-05 14.5842 -6.52447e-05 16.7905C-0.11548 19.2303 0.357204 21.6624 1.37904 23.8861C2.40087 26.1099 3.94259 28.0618 5.87717 29.581C7.57637 31.1621 8.49194 32.0356 8.49194 33.5771H11.8903C11.8903 30.4901 10.0032 28.7589 8.16809 27.0988C6.5844 25.8951 5.32308 24.3261 4.49439 22.5292C3.6657 20.7322 3.29487 18.7619 3.41433 16.7905C3.41433 13.2263 4.84651 9.80815 7.3958 7.2879C9.9451 4.76765 13.4027 3.35179 17.0079 3.35179C20.6132 3.35179 24.0708 4.76765 26.6201 7.2879C29.1694 9.80815 30.6015 13.2263 30.6015 16.7905C30.7163 18.7657 30.3388 20.7385 29.5023 22.5359C28.6658 24.3332 27.3958 25.9 25.8038 27.0988C23.9887 28.7787 22.0935 30.4546 22.0935 33.5968H25.4919C25.4919 32.0553 26.3915 31.166 28.1067 29.585C30.0419 28.0654 31.5839 26.1129 32.6058 23.8883C33.6276 21.6638 34.1 19.231 33.984 16.7905C33.9845 14.5854 33.5455 12.4018 32.6922 10.3645C31.8389 8.3271 30.5878 6.47592 29.0106 4.91668C27.4334 3.35743 25.5609 2.12067 23.5001 1.27705C21.4392 0.433433 19.2305 -0.000511832 16.9999 7.44431e-06Z"
          fill="#161616"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.50781 36.9368H25.4918V40.3162H8.50781V36.9368ZM11.9062 43.6561H22.0934V47.0158H11.9062V43.6561Z"
          fill="#2E8049"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_954_7914">
          <rect width="34" height="48" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export const CheckCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 3.75C15.375 3.75 3.75 15.375 3.75 30C3.75 44.625 15.375 56.25 30 56.25C44.625 56.25 56.25 44.625 56.25 30C56.25 15.375 44.625 3.75 30 3.75ZM26.25 40.1265L16.875 30.7515L19.875 27.7515L26.25 34.1265L40.125 20.2515L43.125 23.2515L26.25 40.1265ZM7.5 30C7.5 42.375 17.625 52.5 30 52.5C42.375 52.5 52.5 42.375 52.5 30C52.5 17.625 42.375 7.5 30 7.5C17.625 7.5 7.5 17.625 7.5 30Z"
        fill="#2E8049"
      />
    </SvgIcon>
  );
};
export const CheckmarkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9 13.1746L2 6.17463L0.599998 7.57463L7.6 14.5746L9 15.9746L23.2 1.77463L21.8 0.374634L9 13.1746Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#2E8049"
      />
    </SvgIcon>
  );
};
export const ChevronDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M8 11L3 6.00005L3.7 5.30005L8 9.60005L12.3 5.30005L13 6.00005L8 11Z" />
    </SvgIcon>
  );
};
export const ChevronUpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M8 5L13 10L12.3 10.7L8 6.4L3.7 10.7L3 10L8 5Z" />
    </SvgIcon>
  );
};
export const CircleEnRouteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.1 31.1438L50.475 18.0188C50.1816 17.3259 49.5024 16.8756 48.75 16.875H43.125V13.125C43.125 12.0895 42.2855 11.25 41.25 11.25H5.625C4.58947 11.25 3.75 12.0895 3.75 13.125V45C3.75 46.0355 4.58947 46.875 5.625 46.875H9.6375C10.5251 50.1412 13.4904 52.4081 16.875 52.4081C20.2596 52.4081 23.2249 50.1412 24.1125 46.875H35.8875C36.7751 50.1412 39.7404 52.4081 43.125 52.4081C46.5096 52.4081 49.4749 50.1412 50.3625 46.875H54.375C55.4105 46.875 56.25 46.0355 56.25 45V31.875C56.2495 31.6237 56.1985 31.375 56.1 31.1438ZM43.125 20.625H47.5125L51.525 30H43.125V20.625ZM16.875 48.75C14.8039 48.75 13.125 47.0711 13.125 45C13.125 42.9289 14.8039 41.25 16.875 41.25C18.9461 41.25 20.625 42.9289 20.625 45C20.625 47.0711 18.9461 48.75 16.875 48.75ZM35.8875 43.125H24.1125C23.2249 39.8588 20.2596 37.5919 16.875 37.5919C13.4904 37.5919 10.5251 39.8588 9.6375 43.125H7.5V15H39.375V38.55C37.6506 39.5508 36.3957 41.1971 35.8875 43.125ZM43.125 48.75C41.0539 48.75 39.375 47.0711 39.375 45C39.375 42.9289 41.0539 41.25 43.125 41.25C45.1961 41.25 46.875 42.9289 46.875 45C46.875 47.0711 45.1961 48.75 43.125 48.75ZM52.5 43.125H50.3625C49.5098 39.8224 46.5359 37.5111 43.125 37.5V33.75H52.5V43.125Z"
        fill="#E5A830"
      />
    </SvgIcon>
  );
};
export const CircleExceptionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 3.75C15.375 3.75 3.75 15.375 3.75 30C3.75 44.625 15.375 56.25 30 56.25C44.625 56.25 56.25 44.625 56.25 30C56.25 15.375 44.625 3.75 30 3.75ZM31.875 15H28.125V33.75H31.875V15ZM27 41.25C27 39.375 28.5 38.25 30 38.25C31.5 38.25 33 39.375 33 41.25C33 43.125 31.5 44.25 30 44.25C28.125 44.25 27 43.125 27 41.25ZM7.5 30C7.5 42.375 17.625 52.5 30 52.5C42.375 52.5 52.5 42.375 52.5 30C52.5 17.625 42.375 7.5 30 7.5C17.625 7.5 7.5 17.625 7.5 30Z"
        fill="#FF0000"
      />
    </SvgIcon>
  );
};
export const CloseFillIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M8 1C4.1 1 1 4.1 1 8C1 11.9 4.1 15 8 15C11.9 15 15 11.9 15 8C15 4.1 11.9 1 8 1ZM10.7 11.5L8 8.8L5.3 11.5L4.5 10.7L7.2 8L4.5 5.3L5.3 4.5L8 7.2L10.7 4.5L11.5 5.3L8.8 8L11.5 10.7L10.7 11.5Z" />
    </SvgIcon>
  );
};
export const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z" />
    </SvgIcon>
  );
};
export const EmpowerEveryoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="43"
      viewBox="0 0 44 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72481 26.2942H21.1304C22.1701 26.2942 23.1996 26.4999 24.1602 26.8997C25.1207 27.2995 25.9935 27.8854 26.7286 28.6241C27.4638 29.3628 28.047 30.2397 28.4448 31.2048C28.8427 32.1699 29.0475 33.2043 29.0475 34.249V42.9999H0.610596V35.4519C0.610596 34.2493 0.846342 33.0584 1.30437 31.9474C1.76241 30.8363 2.43375 29.8268 3.28009 28.9764C4.98933 27.259 7.30757 26.2942 9.72481 26.2942Z"
        fill="#B8E5C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 43H3.05637V35.3209C3.05999 33.2854 3.86636 31.3344 5.29885 29.895C6.73133 28.4557 8.67316 27.6455 10.699 27.6419H19.8715C21.897 27.6455 23.8385 28.4558 25.2705 29.8953C26.7024 31.3347 27.508 33.2858 27.5107 35.3209V43H30.5705V35.3209C30.566 32.4713 29.4373 29.7396 27.4318 27.7246C25.4264 25.7095 22.7077 24.5755 19.8715 24.5709H10.699C7.86256 24.5746 5.14334 25.7083 3.13767 27.7236C1.13201 29.7388 0.00363064 32.471 0 35.3209L0 43ZM15.2853 5.52925e-07C13.1692 5.52925e-07 11.1007 0.630477 9.34122 1.8117C7.58177 2.99293 6.21046 4.67185 5.40068 6.63615C4.59089 8.60046 4.37902 10.7619 4.79184 12.8472C5.20466 14.9325 6.22364 16.848 7.71993 18.3514C9.21621 19.8548 11.1226 20.8787 13.198 21.2934C15.2734 21.7082 17.4246 21.4953 19.3796 20.6817C21.3346 19.8681 23.0055 18.4902 24.1811 16.7224C25.3568 14.9546 25.9843 12.8762 25.9843 10.75C25.9847 9.33816 25.7083 7.94007 25.1708 6.63562C24.6332 5.33116 23.8452 4.14591 22.8516 3.14759C21.858 2.14927 20.6784 1.35745 19.3801 0.817373C18.0819 0.277296 16.6904 -0.000452322 15.2853 5.52925e-07Z"
        fill="black"
      />
      <path
        d="M15.2852 18.429C19.5061 18.429 22.9278 14.991 22.9278 10.75C22.9278 6.50896 19.5061 3.07092 15.2852 3.07092C11.0643 3.07092 7.64258 6.50896 7.64258 10.75C7.64258 14.991 11.0643 18.429 15.2852 18.429Z"
        fill="#B8E5C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6761 15.3582C38.1328 15.3578 37.5949 15.466 37.0937 15.6767C36.5925 15.8874 36.1381 16.1962 35.757 16.5852L35.1567 17.2021L34.5495 16.5852C34.1694 16.1954 33.7158 15.8858 33.2151 15.6744C32.7145 15.463 32.1769 15.3541 31.6338 15.3541C31.0907 15.3541 30.5531 15.463 30.0524 15.6744C29.5518 15.8858 29.0981 16.1954 28.7181 16.5852C27.945 17.3776 27.512 18.443 27.512 19.5527C27.512 20.6624 27.945 21.7278 28.7181 22.5202L35.1567 29.0688L41.5918 22.5202C42.3649 21.7278 42.7979 20.6624 42.7979 19.5527C42.7979 18.443 42.3649 17.3776 41.5918 16.5852C41.2112 16.1965 40.7574 15.8878 40.2568 15.6771C39.7562 15.4664 39.2188 15.358 38.6761 15.3582Z"
        fill="#2E8049"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
export const FightFrictionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="50"
      height="46"
      viewBox="0 0 50 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_954_7892)">
        <path
          d="M46.4299 33.4532L32.1421 41.2382V22.2771L46.4299 14.4921V33.4532Z"
          fill="#2E8049"
        />
        <path
          d="M30.3588 3.78723L44.5097 11.5009L30.3588 19.211L16.2043 11.5009L30.3588 3.78723Z"
          fill="#B8E5C7"
        />
        <path
          d="M49.0742 9.95171L31.2163 0.217714C30.9526 0.0758788 30.6572 0.00158691 30.3571 0.00158691C30.057 0.00158691 29.7617 0.0758788 29.4979 0.217714L11.64 9.95171C11.3645 10.1079 11.1355 10.3334 10.9763 10.6054C10.817 10.8775 10.7332 11.1864 10.7332 11.5009C10.7332 11.8154 10.817 12.1243 10.9763 12.3963C11.1355 12.6683 11.3645 12.8939 11.64 13.05L28.572 22.2772V41.2383L23.1682 38.2863L21.4462 41.3846L29.5159 45.7822C29.7789 45.9263 30.0746 46.0019 30.3751 46.0019C30.6757 46.0019 30.9713 45.9263 31.2343 45.7822L49.0742 36.0518C49.3533 35.8981 49.5861 35.6736 49.7488 35.4014C49.9115 35.1292 49.9982 34.819 50.0001 34.5027V11.5009C50.0005 11.1841 49.9147 10.873 49.7518 10.6004C49.5888 10.3278 49.3548 10.1037 49.0742 9.95171ZM30.3589 3.7872L44.5098 11.5009L30.3589 19.211L16.2116 11.5009L30.3589 3.7872ZM46.43 33.4532L32.1422 41.2383V22.2772L46.43 14.4921V33.4532Z"
          fill="#16181F"
        />
        <path d="M14.2842 19.4608H0V22.9982H14.2842V19.4608Z" fill="#16181F" />
        <path
          d="M17.8542 33.6174H3.57007V37.1548H17.8542V33.6174Z"
          fill="#16181F"
        />
        <path
          d="M21.428 26.5392H7.1438V30.0765H21.428V26.5392Z"
          fill="#16181F"
        />
      </g>
      <defs>
        <clipPath id="clip0_954_7892">
          <rect width="50" height="46" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export const LavenderCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#BF83C0" />
    </SvgIcon>
  );
};
export const MenuIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M2 12H14V13H2V12ZM2 9H14V10H2V9ZM2 6H14V7H2V6ZM2 3H14V4H2V3Z" />
    </SvgIcon>
  );
};
export const Number1Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" fill="#E5B8E5" />
      <path
        d="M17.52 8H15.34C14.98 9.56 13.7 10.46 11.88 10.72V13.1C13.38 13.04 14.46 12.36 15.1 11.26C15.04 11.58 15.02 11.9 15.02 12.24V19.7H10.98V22H21.4V19.7H17.52V8Z"
        fill="#321233"
      />
    </SvgIcon>
  );
};
export const Number2Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" fill="#E5B8E5" />
      <path
        d="M21.1 22V19.66H14.38C14.58 19.26 15.38 18.68 16.3 18.02L17.24 17.36C19.26 15.94 21.1 14.48 21.1 11.9C21.1 9.48 19.36 7.64 16.34 7.64C13.06 7.64 11.1 9.7 11.1 12.8V12.92H13.78V12.78C13.78 10.94 14.64 9.78 16.34 9.78C17.74 9.78 18.56 10.56 18.56 11.88C18.56 13.26 17.52 14.22 15.98 15.3L15.06 15.92C12.94 17.42 11 18.8 10.92 22H21.1Z"
        fill="#321233"
      />
    </SvgIcon>
  );
};
export const Number3Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" fill="#E5B8E5" />
      <path
        d="M16 22.36C19.06 22.36 21.24 20.66 21.24 18.22C21.24 16.3 19.96 15.14 18.38 14.7C19.84 14.3 20.96 13.22 20.96 11.64C20.96 9.22 18.98 7.64 16.16 7.64C13.06 7.64 10.98 9.38 10.84 12.1H13.48C13.58 10.7 14.58 9.78 16.1 9.78C17.58 9.78 18.44 10.58 18.44 11.74C18.44 13.1 17.36 13.7 16.06 13.7H15.06V15.86H16.04C17.66 15.86 18.72 16.72 18.72 17.98C18.72 19.4 17.62 20.22 15.98 20.22C14.42 20.22 13.3 19.28 13.3 17.48H10.74C10.74 20.56 12.72 22.36 16 22.36Z"
        fill="#321233"
      />
    </SvgIcon>
  );
};
export const SeekSimplicityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_954_7901)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.702 30.4786C43.2827 28.7049 42.4286 27.0633 41.2168 25.7015C40.0049 24.3398 38.4732 23.3008 36.7597 22.6779C35.0461 22.0551 33.2045 21.8681 31.4007 22.1336C29.5969 22.3992 27.8874 23.1091 26.4263 24.1994L19.7946 17.5703C21.2268 15.6875 21.9999 13.3861 21.9948 11.0208C22.0014 8.54398 21.1711 6.13748 19.6384 4.19136C18.1057 2.24523 15.9604 0.87353 13.5502 0.298579C11.1401 -0.276372 8.60635 -0.0208801 6.35963 1.02365C4.11292 2.06817 2.28493 3.84052 1.17192 6.05343C0.0589147 8.26634 -0.273872 10.7901 0.227496 13.2157C0.728864 15.6414 2.035 17.8266 3.93422 19.4174C5.83344 21.0082 8.21443 21.9112 10.6913 21.9802C13.1682 22.0491 15.5957 21.2799 17.5806 19.7973L24.1915 26.4264C23.0839 27.9074 22.368 29.6438 22.1099 31.4748C21.8518 33.3058 22.06 35.1723 22.7151 36.9016C23.3702 38.6308 24.4511 40.167 25.8578 41.3678C27.2644 42.5687 28.9514 43.3954 30.7625 43.7716C32.5737 44.1478 34.4505 44.0612 36.2193 43.5199C37.988 42.9785 39.5916 42 40.8817 40.6747C42.1717 39.3494 43.1064 37.7203 43.5994 35.9381C44.0924 34.1558 44.1277 32.2781 43.702 30.4786ZM10.9974 18.8725C9.44385 18.8725 7.9252 18.412 6.63349 17.5493C5.34177 16.6865 4.33501 15.4602 3.74049 14.0255C3.14598 12.5908 2.99043 11.0121 3.29351 9.48903C3.59659 7.96595 4.34469 6.56691 5.4432 5.46883C6.54171 4.37075 7.9413 3.62295 9.46498 3.31999C10.9887 3.01703 12.568 3.17252 14.0033 3.7668C15.4386 4.36107 16.6653 5.36745 17.5284 6.65865C18.3915 7.94986 18.8522 9.4679 18.8522 11.0208C18.8522 13.1032 18.0246 15.1003 16.5516 16.5728C15.0785 18.0453 13.0806 18.8725 10.9974 18.8725Z"
          fill="black"
        />
        <path
          d="M14.0035 18.2753C18.0114 16.6158 19.9146 12.0229 18.2545 8.01657C16.5944 4.01028 11.9996 2.1078 7.99174 3.76726C3.98388 5.42672 2.08065 10.0197 3.74076 14.026C5.40087 18.0323 9.99568 19.9348 14.0035 18.2753Z"
          fill="#B8E5C7"
        />
        <path
          d="M32.8778 40.9695C37.2389 40.9695 40.7742 37.4356 40.7742 33.0762C40.7742 28.7169 37.2389 25.183 32.8778 25.183C28.5168 25.183 24.9814 28.7169 24.9814 33.0762C24.9814 37.4356 28.5168 40.9695 32.8778 40.9695Z"
          fill="#2E8049"
        />
      </g>
      <defs>
        <clipPath id="clip0_954_7901">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export const StoreIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none">
      <rect x="6" y="27" width="35" height="12" fill="#E5D6B8" />
      <rect x="6" y="9" width="36" height="15" fill="#E5A830" />
      <path
        d="M45.0001 16.02L42.0001 7.01996C41.8957 6.70949 41.6926 6.44172 41.4217 6.25754C41.1509 6.07335 40.8272 5.98289 40.5001 5.99996H7.5001C7.173 5.98289 6.8493 6.07335 6.57844 6.25754C6.30759 6.44172 6.10446 6.70949 6.0001 7.01996L3.00009 16.02C2.97848 16.1792 2.97848 16.3407 3.00009 16.5V25.5C3.00009 25.8978 3.15813 26.2793 3.43943 26.5606C3.72074 26.8419 4.10227 27 4.50009 27H6.0001V42H9.00009V27H18.0001V42H42.0001V27H43.5001C43.8979 27 44.2795 26.8419 44.5608 26.5606C44.8421 26.2793 45.0001 25.8978 45.0001 25.5V16.5C45.0217 16.3407 45.0217 16.1792 45.0001 16.02ZM39.0001 39H21.0001V27H39.0001V39ZM42.0001 24H36.0001V18H33.0001V24H25.5001V18H22.5001V24H15.0001V18H12.0001V24H6.0001V16.74L8.58009 8.99996H39.4201L42.0001 16.74V24Z"
        fill="#21232A"
      />
    </SvgIcon>
  );
};
export const YellowCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#E5A830" />
    </SvgIcon>
  );
};

export const getDynamicIcon = (props) => {
  const { name } = props;
  const restOf = _(props).omit("name");

  switch (name) {
    case "ArrowRightIcon":
      return <ArrowRightIcon {...restOf} />;
    case "ChampionCreativityIcon":
      return <ChampionCreativityIcon {...restOf} />;
    case "CheckCircleIcon":
      return <CheckCircleIcon {...restOf} />;
    case "CheckmarkIcon":
      return <CheckmarkIcon {...restOf} />;
    case "ChevronDownIcon":
      return <ChevronDownIcon {...restOf} />;
    case "ChevronUpIcon":
      return <ChevronUpIcon {...restOf} />;
    case "CircleEnRouteIcon":
      return <CircleEnRouteIcon {...restOf} />;
    case "CircleExceptionIcon":
      return <CircleExceptionIcon {...restOf} />;
    case "CloseFillIcon":
      return <CloseFillIcon {...restOf} />;
    case "CloseIcon":
      return <CloseIcon {...restOf} />;
    case "EmpowerEveryoneIcon":
      return <EmpowerEveryoneIcon {...restOf} />;
    case "FightFrictionIcon":
      return <FightFrictionIcon {...restOf} />;
    case "LavenderCircleIcon":
      return <LavenderCircleIcon {...restOf} />;
    case "MenuIcon":
      return <MenuIcon {...restOf} />;
    case "Number1Icon":
      return <Number1Icon {...restOf} />;
    case "Number2Icon":
      return <Number2Icon {...restOf} />;
    case "Number3Icon":
      return <Number3Icon {...restOf} />;
    case "SeekSimplicityIcon":
      return <SeekSimplicityIcon {...restOf} />;
    case "StoreIcon":
      return <StoreIcon {...restOf} />;
    case "YellowCircleIcon":
      return <YellowCircleIcon {...restOf} />;
    default:
      break;
  }
};
