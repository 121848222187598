const typography = {
  letterSpacing: "0em",
  xlTitle: {
    fontFamily: "BoxC Gravity Condensed",
    fontSize: "80px",
    fontWeight: 400,
    lineHeight: "88px",
    "@media screen and (max-width: 1440px)": {
      fontFamily: "BoxC Gravity Condensed",
      fontSize: "38px",
      fontWeight: 400,
      lineHeight: "42px",
    },
  },
  h1: {
    fontFamily: "BoxC Gravity Condensed",
    fontSize: "60px",
    fontWeight: 400,
    lineHeight: "66px",
    "@media only screen and (max-width: 1440px)": {
      fontFamily: "BoxC Gravity Condensed",
      fontSize: "32px",
      fontWeight: 400,
      lineHeight: "35px",
    },
  },
  h2: {
    fontFamily: "BoxC Gravity Condensed",
    fontSize: "44px",
    fontWeight: 400,
    lineHeight: "48px",
    "@media only screen and (max-width: 1440px)": {
      fontfamily: "BoxC Gravity Condensed",
      fontSize: "24px",
      fontWeight: 400,
      linHeight: "26px",
    },
  },
  h3: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "31px",
  },
  h4: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "25px",
  },
  h5: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  h6: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.04em",
  },
  body1: {
    fontFamily: "BoxC Diatype",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  body: {
    fontFamily: "BoxC Diatype",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  body2: {
    fontFamily: "BoxC Diatype",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "25px",
  },
  caption: {
    fontFamily: "BoxC Diatype",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17px",
  },
  monoBody: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  monoBodySmall: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  monoCaption: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17px",
  },
  robotoCondensed: {
    fontFamily: "Roboto Condensed",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "60px",
    lineHeight: "110%",
  },
  roundButton: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
  },
  logistics: {
    fontFamily: "BoxC Diatype",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "25px",
  },
  about: {
    fontFamily: "BoxC Diatype Mono",
    fontSize: "130px",
    fontWeight: 400,
    lineHeight: "182px",
  },
  requestMoreInfo: {
    fontFamily: "BoxC Gravity Condensed",
    fontSize: "32px",
    fontWeight: 900,
    lineHeight: "35px",
  },
};

export default typography;
