import Hero from "../../components/Hero";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Img } from "../../components/Image";
import useStrapi from "../../hooks/useStrapi";
import { useEffect, useState } from "react";

export const AboutHero = () => {
  const { headers, fetchHeaders, fetchingHeaders } = useStrapi();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (headers === null && !fetchingHeaders.current) {
      fetchHeaders();
    } else {
      if (data === null) {
        if (!!headers && "about" in headers && headers["about"] != null) {
          setData(headers["about"]);
        }
      }
    }
  }, [headers, fetchHeaders, fetchingHeaders, data]);

  return (
    <>
      {!!data ? (
        <Hero
          title={
            <Box
              mt={{ xs: 10, lg: 20 }}
              mx={{ xs: 2, lg: 10 }}
              mb={{ xs: 2, lg: 5 }}
            >
              <Typography
                variant={data["title"]["font"]}
                color={
                  data["title"]["color"].toLowerCase() === "light"
                    ? "gray.main"
                    : "black.main"
                }
              >
                {data["title"]["value"]}
              </Typography>
            </Box>
          }
          subTitle={
            <Stack ml={{ xs: 2, lg: 10 }} mr={{ xs: 2, lg: 20 }} rowGap={4}>
              {data["subTitle"]["value"]
                .split("\n\n")
                .map((paragraph, index) => (
                  <Box key={`about-subtitle-p-${index}`}>
                    <Typography
                      variant={data["subTitle"]["font"]}
                      color={
                        data["subTitle"]["color"].toLowerCase() === "light"
                          ? "black.light"
                          : "black.main"
                      }
                    >
                      {paragraph}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          }
          image={
            <Box mr={{ lg: 10 }}>
              <Img
                maxHeight="auto"
                maxWidth="646px"
                mx="auto"
                pt={5}
                src={!!data ? data["image"]["data"]["attributes"]["url"] : ""}
                alt="about-hero-img"
              />
            </Box>
          }
          doTransitions={false}
        />
      ) : (
        <Box
          sx={{
            height: "700px",
            width: "100vw",
            backgroundColor: "black.main",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
};
