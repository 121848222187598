import { Box, Grid } from "@mui/material";
import MediaCard from "../../components/MediaCard";
import ColumnCard from "../../components/ColumnCard";
import { forwardRef } from "react";

const Summary = forwardRef((props, ref) => {
  return (
    <Grid container item maxWidth={1440} mx="auto">
      <Grid container item xs={12} zeroMinWidth pt={{ xs: 5, lg: 10 }}>
        <Box px={{ xs: 2, sm: 5, lg: 10 }}>
          <Grid container>
            <MediaCard
              page="how-it-works"
              index={0}
              sizes={[6, 6]}
              imageConfig={{
                maxHeight: 513,
                maxWidth: 782,
                mx: "auto",
              }}
            />
            <ColumnCard
              page="how-it-works"
              index={0}
              ref={ref}
              xs={12}
              lg={12}
              columnSpacing={12}
              rowSpacing={2}
              pt={{
                xs: 5,
                lg: 10,
              }}
              columnConfig={{
                rowGap: 2,
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
});

export default Summary;
