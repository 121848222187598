import Hero from "../../components/Hero";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Img } from "../../components/Image";
import useStrapi from "../../hooks/useStrapi";
import { useEffect, useRef, useState } from "react";

export const EcommerceHero = () => {
  const { headers, fetchHeaders } = useStrapi();
  const isLoading = useRef(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (headers === null && !isLoading.current) {
      isLoading.current = true;
      fetchHeaders();
    } else {
      isLoading.current = false;

      if (data === null) {
        if (
          !!headers &&
          "e-commerce" in headers &&
          headers["e-commerce"] != null
        ) {
          setData(headers["e-commerce"]);
        }
      }
    }
  }, [headers, fetchHeaders, data]);

  return (
    <>
      {!!data ? (
        <Hero
          title={
            <Box mt={{ xs: 10, lg: 22 }} ml={{ xs: 2, lg: 17 }}>
              <Typography
                variant={data["title"]["font"]}
                color={
                  data["title"]["color"].toLowerCase() === "light"
                    ? "gray.main"
                    : "black.main"
                }
              >
                {data["title"]["value"]}
              </Typography>
            </Box>
          }
          subTitle={
            <Box ml={{ xs: 2, lg: 17 }} mr={{ xs: 2, lg: 15 }}>
              <Typography
                variant={data["subTitle"]["font"]}
                color={
                  data["subTitle"]["color"].toLowerCase() === "light"
                    ? "black.light"
                    : "black.main"
                }
              >
                {data["subTitle"]["value"]}
              </Typography>
            </Box>
          }
          image={
            <Box mr={{ lg: 10 }}>
              <Img
                maxHeight="auto"
                maxWidth="646px"
                mx="auto"
                pt={5}
                src={!!data ? data["image"]["data"]["attributes"]["url"] : ""}
                alt="ecommerce-hero-img"
              />
            </Box>
          }
          doTransitions={false}
        />
      ) : (
        <Box
          sx={{
            height: "700px",
            width: "100vw",
            backgroundColor: "black.main",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
};
